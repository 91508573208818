import { Component, OnInit } from '@angular/core';
import { ExplorationService } from '../services/exploration.service';
import { DetailsState } from '../store/details.reducer';
import { Store } from '@ngrx/store';
import { WeatherForecastPlaceFullDetail } from '../models/generated/WeatherForecastPlaceFullDetail.generated';
import { TodayTomorrow } from '../models/TodayTomorrowEnum';
import { FavService } from '../services/fav.service';
// import { PointOfInterest } from '../models/generated/PointOfInterest.generated';
// import { resetAndAddPois } from '../store/map.actions';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { Place } from '../models/generated/Place.generated';

interface SearchArgs {
  searchTerm: string;
  favs: Place[];
}

@Component({
  selector: 'details-view',
  templateUrl: './details-view.component.html',
  styleUrls: ['./details-view.component.css']
})
export class DetailsViewComponent implements OnInit {
  readonly TodayTomorrow = TodayTomorrow;

  // poiDistanceKm = 30;
  
  detailsState$: Observable<DetailsState>;

  // Object from backend api:
  fullDetails: WeatherForecastPlaceFullDetail | null = null;

  loading: boolean = true;
  loadingFailed: boolean = false;
  isSearchLoading: boolean = false;
  
  selectedPlace: Place | null = null;
  optionList: Place[] = [];
  searchChange$ = new Subject<SearchArgs>();
  currentTabIndex: number = 0;

  constructor(private explorationService: ExplorationService,
    private favService: FavService,
    private store: Store<{ details: DetailsState }>) {
    this.detailsState$ = store.select('details');

    this.detailsState$.subscribe((details: DetailsState) => {
      this.loading = true;
      this.selectedPlace = details.place;

      if (this.selectedPlace !== null) {
        this.optionList = [this.selectedPlace];

        this.loadSelectedPlace();
      }
    });
  }

  swipeRight() {
    // disabled, bugs, non priority
    // console.log('test')
    // const numTabs: number = this.fullDetails && this.fullDetails.pointsOfInterest ? 4 : 3;
    // this.currentTabIndex = (this.currentTabIndex + 1) % numTabs;
  }

  swipeLeft() {
    // disabled, bugs, non priority
    // const numTabs: number = this.fullDetails && this.fullDetails.pointsOfInterest ? 4 : 3;
    // this.currentTabIndex = (this.currentTabIndex - 1) < 0 ? numTabs - 1 : (this.currentTabIndex - 1);
  }

  loadSelectedPlace(ignoreCache: boolean = false) {
    this.loading = true;

    if (this.selectedPlace === null)
    {
      console.log("Selected place null");
      return;
    }

    this.explorationService.getPlaceDetails(this.selectedPlace, ignoreCache).subscribe(details => {
      console.log("Full details model:");
      console.log(details);
      this.fullDetails = details;

      this.loading = false;
    }, error => {
      this.loadingFailed = true;
      console.error("place loading failed");
    });
  }

  get favs(): Place[] {
    return this.favService.getFavorites();
  }

  isFav(): boolean {
    return this.selectedPlace !== null && this.favs.map(fav => fav.name.split(',')[0]).includes(this.selectedPlace.name.split(',')[0]);
  }

  toggleFav() {
    if (!this.selectedPlace) {
      return;
    }

    if (this.isFav()) {
      this.favService.removeFavorite(this.selectedPlace);
    }
    else {
      this.favService.addFavorite(this.selectedPlace);
    }
  }

  ngOnInit(): void {
    const getResults = (args: SearchArgs): Observable<Place[]> =>
      this.explorationService.getPlaceSearchResults(args.searchTerm, args.favs)
          .pipe(
            catchError(() => of({ results: [] })),
            map((res: any) => res)
          );

    const optionList$: Observable<Place[]> = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500))
      .pipe(switchMap(getResults));

    optionList$.subscribe(data => {
      this.optionList = data;
      this.isSearchLoading = false;
    });
  }

  onSearch(value: string): void {
    this.isSearchLoading = true;
    this.searchChange$.next( { searchTerm: value, favs: this.favs });
  }

  // findPois(): void {
  //   this.loading = true;
  //   this.explorationService.getPoiForPlace(this.fullDetails!.placeName, this.poiDistanceKm).subscribe(poiList => {
  //     // Send pois to map, reset first
  //     this.store.dispatch(resetAndAddPois({
  //       pois: poiList
  //     }));

  //     this.loading = false;
  //   }, error => {
  //     this.loadingFailed = true;
  //   });
  // }
}
