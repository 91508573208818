import { Action, createReducer, on } from '@ngrx/store';
import { PointOfInterest } from '../models/generated/PointOfInterest.generated';
import { addPois, resetPois, resetAndAddPois } from './map.actions';

export const pois: PointOfInterest[] = [];

const _mapReducer = createReducer(
    pois,
    on(addPois, (state: PointOfInterest[], { pois }): PointOfInterest[] => state.concat(pois)),
    on(resetPois, (): PointOfInterest[] => []),
    on(resetAndAddPois, (state: PointOfInterest[], { pois }): PointOfInterest[] => pois)
);

export function mapReducer(state: PointOfInterest[] | undefined, action: Action) {
    return _mapReducer(state, action);
}