import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';
import { WeatherForecastPlaceFullDetail } from '../models/generated/WeatherForecastPlaceFullDetail.generated';
import { TodayTomorrow } from '../models/TodayTomorrowEnum';

@Component({
  selector: 'details-view-day-tab',
  templateUrl: './details-view-day-tab.component.html',
  styleUrls: ['./details-view-day-tab.component.css']
})
export class DetailsViewDayTabComponent implements OnInit, OnChanges {
  readonly TodayTomorrow = TodayTomorrow;

  @Input() fullDetails!: WeatherForecastPlaceFullDetail;
  @Input() day!: TodayTomorrow;
  
  hourGroup!: WeatherForecastHourGroup;
  mainTemperature!: number;
  mainIconSrc!: string;
  mainFeelTemperature!: number;
  mainLongDescription!: string;
  sunrise!: string;
  sunset!: string;
  ultravioletIndex!: number;
  humidty!: number;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    this.setInternalModel();
  }

  ngOnInit(): void {
    this.setInternalModel();
  }

  setInternalModel() {
    if (this.day == TodayTomorrow.Today) {
      this.hourGroup = this.fullDetails.today.hourGroup;
      this.mainTemperature = this.fullDetails.today.hourGroup.hours[0].temperature;
      this.mainIconSrc = this.fullDetails.today.hourGroup.hours[0].iconFullUrl;
      this.mainFeelTemperature = this.fullDetails.today.hourGroup.hours[0].feelTemperature;
      this.mainLongDescription = this.fullDetails.today.hourGroup.hours[0].longDescription;
      this.sunrise = this.fullDetails.today.sunriseDisplayText;
      this.sunset = this.fullDetails.today.sunsetDisplayText;
      this.humidty = this.fullDetails.today.hourGroup.hours[0].humidity;
      this.ultravioletIndex = this.fullDetails.today.hourGroup.hours[0].ultravioletIndex;
    }
    else {
      this.hourGroup = this.fullDetails.tomorrow.hourGroup;
      this.mainTemperature = this.fullDetails.forecast8DaysMinimal![1].temperatureDay;
      this.mainIconSrc = this.fullDetails.forecast8DaysMinimal![1].iconFullUrl;
      this.mainFeelTemperature = this.fullDetails.forecast8DaysMinimal![1].feelTemperatureDay;
      this.mainLongDescription = this.fullDetails.forecast8DaysMinimal![1].longDescription;
      this.sunrise = this.fullDetails.tomorrow.sunriseDisplayText;
      this.sunset = this.fullDetails.tomorrow.sunsetDisplayText;
      this.humidty = this.fullDetails.forecast8DaysMinimal![1].humidity;
      this.ultravioletIndex = this.fullDetails.forecast8DaysMinimal![1].ultravioletIndex;
    }
  }

}
