<div [ngClass]="horizontal ? 'horizontal-overflow' : 'vertical-wrap'"
    [ngStyle]="{'font-size': compact ? '12px' : 'inherit', 'padding': compact ? '2px' : '10px', 'background-color': compact ? 'inherit' : 'rgba(51, 51, 93, 0.636)'}"
    style="display:flex; flex-direction: row; align-content: flex-start;gap: 10px 10px;border-radius: 7px;justify-content: flex-start;">
    <div *ngFor="let hour of hourGroup.hours" style="flex: none;">
        <span [ngClass]="getTemperatureStyling(hour.temperature)" [ngStyle]="{'font-size': compact ? '11px' : 'inheirt'}">
            {{hour.temperature| number: '1.0-0'}}°
            <br/>
        </span>

        <span>
            <!-- cloud % disabled for now: -->
            <!-- {{(hour.clouds * 100 | number: '1.0-0')}}%<br/> -->
            <img [src]="hour.iconFullUrl" [alt]="hour.shortDescription" [ngStyle]="{'width': compact ? '18px' : '40px'}" />
        </span>

        <span [ngStyle]="{'font-size': compact ? '11px' : 'inheirt'}"><br/>
            {{hour.dateTimeDisplayText}}
        </span>

        <span class="wind" [ngStyle]="{'font-size': compact ? '11px' : 'inheirt'}">
            <br/>
            {{(hour.windSpeed | number: '1.0-0')}}<span class="wind-speed">&nbsp;km/h</span><span *ngIf="showWindDirection" style="font-size:10px">&nbsp;{{ hour.windSpeedDirectionDisplayName }}</span>
            <ng-container *ngIf="!compact && hour.windSpeedGust && roundUp(hour.windSpeedGust) > roundUp(hour.windSpeed) + 1">
                <br/>
                <i>{{( hour.windSpeedGust | number: '1.0-0')}}<span class="wind-speed">&nbsp; max</span></i>
            </ng-container>
        </span>
        
        <!-- rain or snow -->
        <span *ngIf="!compact && hour.rain1h > 0" class="rain">
            <br/>
            <span *ngIf="hour.rain1h > 0 && hour.rain1h <= 0.8" class="rain">
                <img class="rain-icon" src="assets/svg/Cloud-Drizzle.svg" alt="rain" />

            </span>

            <span *ngIf="hour.rain1h > 0.8 && hour.rainVolumeMm && hour.rainVolumeMm > 0" class="rain">
                <img class="rain-icon" src="assets/svg/Cloud-Rain.svg" alt="rain" />
            </span>

            <span *ngIf="hour.rain1h > 0.8 && hour.snowVolumeMm && hour.snowVolumeMm > 0" class="rain">
                <img class="rain-icon" src="assets/svg/Cloud-Snow.svg" alt="rain" />
            </span>
            {{ hour.rain1h * 100 | number: "1.0-0" }}%

            <ng-container *ngIf="hour.rainVolumeMm && hour.rainVolumeMm > 0">
                <br/>{{ hour.rainVolumeMm | number: "1.0-2" }}<span style="font-size:8px">mm</span>
            </ng-container>

            <ng-container *ngIf="!(hour.rainVolumeMm && hour.rainVolumeMm > 0) && (hour.snowVolumeMm && hour.snowVolumeMm > 0)">
                <br/>{{ hour.snowVolumeMm | number: "1.0-2" }}<span style="font-size:8px">mm</span>
            </ng-container>

            <ng-container *ngIf="((hour.rainVolumeMm === null || hour.rainVolumeMm === 0) && (hour.snowVolumeMm === null || hour.snowVolumeMm === 0) && hour.rain1h > 0)"> 
                <br/>&lt;.01<span style="font-size:8px">mm</span>
            </ng-container>

            <!-- <ng-container *ngIf="!hour.snowVolumeMm && hour.snowVolumeMm === 0 && hour.rain1h > 0">
                <br/>&lt;.01<span style="font-size:8px">mm</span>
            </ng-container> -->
        </span>
    </div>
</div>