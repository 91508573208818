<!-- [nzScroll]="{ x: '100vw', y: '100vw' }" -->

<div id="quick-filter" [hidden]="loading">
  <div style="float:left;">
    <!-- <button nz-button nzType="default" [nzSize]="'default'" nzShape="circle" (click)="expandedClicked()"><i nz-icon [nzType]="expandIcon"></i></button>
     -->
    <nz-select [(ngModel)]="selectedDay" (ngModelChange)="selectedDayChanged($event)" style="margin-left: 10px; width:150px;">
      <nz-option nzValue="today" nzLabel="Today"></nz-option>
      <nz-option nzValue="tomorrow" nzLabel="Tomorrow"></nz-option>
      <nz-option nzValue="after-tomorrow" nzLabel="After tomorrow"></nz-option>
    </nz-select>
    
  </div>
  <div style="float:right;">

    Filter weather <nz-switch [(ngModel)]="isAllFiltersEnabled" style="margin-top:5px;" (ngModelChange)="toggleFiltersEnabled($event)"></nz-switch>
  </div>
  <div style="clear:both;"></div>
  
<div style="padding-left: 10px;padding-right: 10px;">
  <nz-slider [hidden]="!isQuickFilterExpanded" (nzOnAfterChange)="sliderTimeChanged($event)" [nzMarks]="marksTime" nzStep="100" [ngModel]="[selectedFrom,selectedTo]" nzRange nzMin="900" nzMax="2300" [nzTipFormatter]="formatterTime"></nz-slider>
  
  <div style="margin-bottom:-8px;margin-top:4px;">Distance from {{ originName }} <button style="margin-left: 2px;" nz-button nzType="primary" nzSize="default" nzShape="circle" (click)="setCurrentLocationOrigin()"><i class='bx bx-map nav__icon'></i></button></div>
  <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksDistanceMinutes" nzMin="10" nzStep="10" [(ngModel)]="distanceMin" [nzMax]="180" [nzTipFormatter]="formatterDistanceMinutes"></nz-slider>
</div>

</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
      <div class="search-box">
        <input type="text" nz-input placeholder="Search name" [(ngModel)]="searchValue" (keyup)="search(true, $event)" />
        <button nz-button nzSize="small" nzType="primary" (click)="search(false)" class="search-button">Search</button>
        <button nz-button nzSize="small" (click)="reset()">Reset</button>
      </div>
    </div>
  </nz-dropdown-menu>

<nz-table class="weather-list-table" [hidden]="loading" nzTableLayout="auto" #nestedTable [nzData]="listOfDisplayData"  nzSize="small" [nzPageSize]="1000" [nzShowPagination]="false">
    <thead>
      <tr>
        <th nzWidth="185" nzCustomFilter>Place
            <nz-filter-trigger [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
                <i nz-icon nzType="search"></i>
            </nz-filter-trigger>
        </th>
        <th
          [nzSortOrder]="null"
          [nzSortFn]="tempSortFn"
          [nzSortDirections]="['ascend', 'descend', null]">C°</th>
        <th
        [nzSortOrder]="null"
        [nzSortFn]="cloudsSortFn"
        [nzSortDirections]="['ascend', 'descend', null]">Cloudy</th>
        <th
        [nzSortOrder]="null"
        [nzSortFn]="windSortFn"
        [nzSortDirections]="['ascend', 'descend', null]">Wind</th>
      </tr>
    </thead>
    <tbody>
      <ng-template ngFor let-data [ngForOf]="nestedTable.data">
        <tr (click)="data.expand = !data.expand; data.expand && setExpandedElement(data);"
            [class.favorite-place]="data.isFav">
          <td>
            
<!-- 
                <i *ngIf="data.avgClouds! <= 0.65"
                class="material-icons sunny">
                wb_sunny
            </i>
            &nbsp;
            <i *ngIf="data.avgClouds! >= 0.4"
                class="material-icons cloudy">
                wb_cloudy
            </i>
            &nbsp; -->

            <img [src]="data.isFav ? data.iconFullUrl.replace('weather-icons-transparent', 'weather-icons-favs') : data.iconFullUrl" style="width:30px; margin-right: 5px;" />

            <!-- <span class="favorite-field">
            <i *ngIf="data.isFav === true"
            class="material-icons favorite">
                star
            </i>
          </span> -->

                <a class="weather-place-link">{{ data.place }}</a>
            
          </td>
          <td [ngClass]="getTemperatureStyling(data.avgTemperature)">
            
            {{data.avgTemperature | number: '1.0-0'}}

        </td>
        <td [class.bold]="data.avgClouds! < 0.25">
            {{(data.avgClouds! * 100 | number: '1.0-0')}}%


        </td>
        <td [class.bold]="data.avgWindSpeed > 15">
          <span class="wind-speed">
            {{data.avgWindSpeed | number: '1.0-0'}}
            <span class="wind-speed-small"> km/h</span>

          </span>
      </td>
        </tr>
        <tr [nzExpand]="data.expand">
            <td colspan="4">
              <div
              [@detailExpand]="data.expand ? 'expanded' : 'collapsed'">
              <place-quick-details
                  [element]=data
                  [context]="'list'"
                  [isExpanded]="data.expand"></place-quick-details>
              </div>
            </td>

          <!-- <nz-table [nzScroll]="{ x: '100vw', y: '100vw' }" #innerTable [nzData]="listOfChildrenData" nzSize="small" [nzShowPagination]="false">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Status</th>
                <th>Upgrade Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of innerTable.data">
                <td>{{ data.date }}</td>
                <td>{{ data.name }}</td>
                <td>
                  <nz-badge [nzStatus]="'success'" [nzText]="'Finished'"></nz-badge>
                </td>
                <td>{{ data.upgradeNum }}</td>
                <td>
                  <span class="table-operation">
                    <a nz-dropdown class="operation" [nzDropdownMenu]="menu">
                      Pause
                      <i nz-icon nzType="down"></i>
                    </a>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item>
                          <a>Action 1</a>
                        </li>
                        <li nz-menu-item>
                          <a>Action 2</a>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a class="operation">Stop</a>
                    <nz-divider nzType="vertical"></nz-divider>
                    <a>More</a>
                  </span>
                </td>
              </tr>
            </tbody>
          </nz-table> -->
        </tr>
      </ng-template>
    </tbody>
</nz-table>