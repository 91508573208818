<div class="place-quick-details" *ngIf="context === 'list'">
    <weather-hours [hourGroup]="element" [horizontal]="true"></weather-hours>


    <div style="margin-top:5px;">
        <div style="float:left;">Driving distance: {{element.drivingDurationDisplayText}}<br/>
            <a style="text-decoration: underline;" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination={{element.coords!.lat}}+{{element.coords!.long}}">
                Google maps
            </a>
        </div>
        <div style="float:right;">
            <button *ngIf="element.webcamHtml != null && element.webcamHtml != ''" nz-button nzType="default" [nzSize]="size" style="margin-top:3px;margin-right:3px;" (click)="showWebcamInList = !showWebcamInList">
                <i nz-icon nzType="video-camera"></i> Cam
            </button>
            <button nz-button nzType="primary" [nzSize]="size" style="margin-top:3px;" (click)="openDetails(element)">
                <i nz-icon nzType="unordered-list"></i> Details
            </button>
        </div>
        <div style="clear:both;"></div>
    </div>

    <div *ngIf="showWebcamInList" class="webcam-container-list" style="margin-top:5px;max-width:320px;margin-right: auto;margin-left: auto;" [innerHTML]="element.webcamHtml | safe:'html'"></div>

</div>

<div *ngIf="context === 'map'">
    <div *ngIf="showWebcamInMap" class="webcam-container-list" style="margin-top:5px;max-width:320px;margin-right: auto;margin-left: auto;" [innerHTML]="element.webcamHtml | safe:'html'"></div>
    <span>
        <img [src]="element.isFav ? element.iconFullUrl.replace('weather-icons-transparent', 'weather-icons-favs') : element.iconFullUrl" style="height:60px; margin-right: 5px;float:left;" />
    </span>
    <span>
        <button *ngIf="element.webcamHtml != null && element.webcamHtml != ''" nz-button nzType="default" [nzSize]="'small'" style="margin-top:3px;margin-right:3px;" (click)="showWebcamInMap = !showWebcamInMap">
            <i nz-icon nzType="video-camera"></i> Cam
        </button>

        <span [ngClass]="getTemperatureStyling(element.avgTemperature)">{{element.avgTemperature | number: '1.0-0'}} °C<br/></span>
    </span>
    <span [class.bold]="element.avgClouds! < 0.25">
        <span style="font-size:12px;">Clouds:</span>&nbsp;<strong>{{(element.avgClouds! * 100 | number: '1.0-0')}}%</strong>&nbsp;
    </span>
    <span [class.bold]="element.avgWindSpeed > 15">
        <span>
        <span style="font-size:12px;">Wind:</span>&nbsp;<strong>{{element.avgWindSpeed | number: '1.0-0'}}</strong>
        <span style="font-size:12px;"> km/h</span>

        </span>
    </span>
    <br/>
    {{element.hours[0].dateTimeDisplayText}} - {{  element.hours[element.hours.length - 1].dateTimeDisplayText }}, driving distance {{ element.drivingDurationDisplayText }}
</div>


<div *ngIf="context === 'map-hours'">
    <div style="height:71px;">
        <weather-hours [hourGroup]="element" [compact]="true" [horizontal]="true"></weather-hours>
    </div>
</div>