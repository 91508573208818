import { Component, Input, OnInit } from '@angular/core';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';

@Component({
  selector: 'weather-hours',
  templateUrl: './weather-hours.component.html',
  styleUrls: ['./weather-hours.component.css']
})
export class WeatherHoursComponent implements OnInit {
  @Input() hourGroup!: WeatherForecastHourGroup;
  @Input() showWindDirection: boolean = false;
  @Input() horizontal: boolean = false;
  @Input() compact: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  roundUp(value: number): number {
    return Math.ceil(value);
  }

  getTemperatureStyling(temperature: number): string {
    if (temperature > 27) {
      return 'hot;'
    }
    else if (temperature > 18) {
      return 'comfortable';
    }
    else if (temperature > 14) {
      return 'almost-comfortable'
    }
    else if (temperature >= 10) {
      return 'little-cold';
    }
    else {
      return 'cold;'
    }
  }
}
