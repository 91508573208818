import { Component, Input } from '@angular/core';
import { ExplorationService } from '../services/exploration.service';
import { Store } from '@ngrx/store';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { openDetails } from '../store/details.actions';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';

@Component({
  selector: 'place-quick-details',
  templateUrl: './place-quick-details.component.html',
  styleUrls: ['./place-quick-details.component.css']
})
export class PlaceQuickDetailsComponent {
  @Input() element!: WeatherForecastHourGroup;
  @Input() context!: string; // TODO: convert to enum: list | map
  @Input() isExpanded: boolean = false;

  loading = false;
  loadingFailed = false;
  size: NzButtonSize = 'large';
  showWebcamInList = false; // save as global in store observable
  showWebcamInMap = false; // save as global in store observable

  constructor(
    private explorationService: ExplorationService,
    private store: Store) {
    // empty
  }

  openDetails(place: WeatherForecastHourGroup) {
    this.store.dispatch(openDetails({
      place: {
        coords: place.coords!,
        name: place.place
      },
      switchView: true
    }));
  }

  getTemperatureStyling(temperature: number): string {
    if (temperature > 27) {
      return 'hot;'
    }
    else if (temperature > 18) {
      return 'comfortable';
    }
    else if (temperature > 14) {
      return 'almost-comfortable'
    }
    else if (temperature >= 10) {
      return 'little-cold';
    }
    else {
      return 'cold;'
    }
  }
}
