<!-- [options]="{styles: this.styles}" -->
<div id="quick-filter" [hidden]="loading">
    <div style="float:left;">
      <button nz-button nzType="default" [nzSize]="'default'" nzShape="circle" (click)="expandedClicked()"><i nz-icon [nzType]="expandIcon"></i></button>

      <nz-select [(ngModel)]="selectedDay" (ngModelChange)="selectedDayChanged($event)" style="margin-left: 10px; width:150px;">
        <nz-option nzValue="today" nzLabel="Today"></nz-option>
        <nz-option nzValue="tomorrow" nzLabel="Tomorrow"></nz-option>
        <nz-option nzValue="after-tomorrow" nzLabel="After tomorrow"></nz-option>
      </nz-select>
    </div>
    <div style="float:right;">
        Filter weather <nz-switch [(ngModel)]="isAllFiltersEnabled" style="margin-top:5px;" (ngModelChange)="toggleFiltersEnabled($event)"></nz-switch>
    </div>
    <div style="clear:both;"></div>

  <div style="padding-left: 10px;padding-right: 10px;">
    <nz-slider (nzOnAfterChange)="sliderTimeChanged($event)" [nzMarks]="marksTime" nzStep="100" [ngModel]="[selectedFrom,selectedTo]" nzRange nzMin="900" nzMax="2300" [nzTipFormatter]="formatterTime"></nz-slider>

    <div style="margin-bottom:-8px;margin-top:4px;">Distance from {{ originName}} <button style="margin-left: 2px;" (click)="setCurrentLocationOrigin()" nz-button nzType="primary" nzSize="default" nzShape="circle"><i class='bx bx-map nav__icon'></i></button></div>
    <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksDistanceMinutes" nzMin="10" nzStep="10" [(ngModel)]="distanceMin" [nzMax]="180" [nzTipFormatter]="formatterDistanceMinutes"></nz-slider>

    <div [hidden]="!isQuickFilterExpanded">
        <nz-switch [(ngModel)]="mapShowPois" style="margin-top:5px;"></nz-switch> Show points of interest for selected place
      <button nz-button nzType="default" (click)="reload()" style="margin-left: 10px;">Reload page</button>

    </div>
</div>
  
  </div>

<div style="height: 100px;">
    <google-map id="map"
    [hidden]="loading"
    [center]="{lat: lat, lng: lng}"
    [zoom]="initialZoom"
    (zoomChanged)="zoomChanged()"
    [options]="mapOptions"
    width="100%"
    height="50vh"
    (mapClick)="mapClick()">

    <map-marker *ngFor="let forecastGroup of forecastGroups"
    #marker="mapMarker"
    [position]="{lat: forecastGroup.coords!.lat, lng:forecastGroup.coords!.long}"
    [options]="{zIndex: getZIndexForMarker(forecastGroup), icon: {url: getIconForMarker(forecastGroup), scaledSize: getSizeForMarker(forecastGroup), anchor: markerAnchor}}"
    (mapClick)="openInfoWindow(forecastGroup)"></map-marker>

    <map-marker *ngFor="let poi of pois"
    #poiMarker="mapMarker"
    [position]="{lat: poi.coords!.lat, lng:poi.coords!.long}"
    [options]="{icon: getIconForPoi(poi), visible: mapShowPois && mapShowPoisAtThisLevel()}"
    (mapClick)="openInfoWindowForPoi(poiMarker, poi)"></map-marker>
    </google-map>
    
</div>


<div id="selected-item-popup" *ngIf="infoContent !== null || poiInfoContent !== null">
    <div  id="selected-item-popup-inner">
        <div *ngIf="poiInfoContent">
            <h3 style="margin-bottom:3px;float:left;white-space: nowrap;overflow: auto;width: calc(100% - 85px);">{{poiInfoContent.name}}</h3>
            <div style="float:right;width:84px">
                <button nz-button nzType="primary" [nzSize]="'small'" style="margin-top:4px;margin-right:4px" (click)="poiLinkClicked(poiInfoContent)">
                    <i nz-icon nzType="link"></i>
                    Details
                  </button>
            </div>
            <div style="clear:both;"></div>
            <div  style="font-size:11px;height: 60px;overflow: auto;padding-right:4px;">
                <img *ngIf="poiInfoContent.picture" [src]="poiInfoContent.picture" height="58" style="float:left;margin-right: 5px;margin-bottom:3px" />
                <div [innerHTML]="poiInfoContent.description | safe: 'html'"></div>
            </div>
            
        </div>
    
        <div *ngIf="infoContent">
            <h3 style="margin-bottom:3px;float:left;white-space: nowrap;overflow: auto;width: calc(100% - 185px);">{{infoContent.place}}</h3>
            <div style="float:right;width:180px">
                <button nz-button nzType="primary" [nzSize]="'small'" style="margin-top:4px;margin-right:4px;float:right;" (click)="openDetails(infoContent)">
                    <i nz-icon nzType="unordered-list"></i>
                    Details
                </button>
                <button nz-button nzType="default" [nzSize]="'small'" style="margin-top:4px;margin-right:4px;float:right;" (click)="showHours(infoContent)">
                    <i nz-icon [nzType]="quickDetailsContext === 'map' ? 'field-time' : 'info-circle'"></i>
                    {{ quickDetailsContext === 'map' ? 'Hours' : 'Info' }}
                  </button>
            </div>
            <div style="clear:both;"></div>
    
            <place-quick-details
                [element]=infoContent
                [context]="quickDetailsContext"
                [isExpanded]="false"></place-quick-details>
        </div>
    </div>
</div>