<loader [loading]="disableGoAndShowLoading" [loadingFailed]="loadingFailed"></loader>

<div [hidden]="disableGoAndShowLoading" id="filters-window">

    <div style="float:left;min-width:100px; padding: 10px;font-size:16px;padding-bottom:5px;">

        <!-- <nz-radio-group [(ngModel)]="selectedDay" (ngModelChange)="selectedDayChanged($event)">
            <label nz-radio-button nzValue="today">Today</label>
            <label nz-radio-button nzValue="tomorrow">Tomorrow</label>
            <label nz-radio-button nzValue="after-tomorrow">After tomorrow</label>
        </nz-radio-group> -->

        <nz-select [(ngModel)]="selectedDay" (ngModelChange)="selectedDayChanged($event)" style="width:150px;">
            <nz-option nzValue="today" nzLabel="Today"></nz-option>
            <nz-option nzValue="tomorrow" nzLabel="Tomorrow"></nz-option>
            <nz-option nzValue="after-tomorrow" nzLabel="After tomorrow"></nz-option>
        </nz-select>

        <!-- <a nz-dropdown [nzDropdownMenu]="menu">
        {{ selectedItem }}
        <i nz-icon nzType="down"></i>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="setDay('Today')">Today</li>
                <li nz-menu-item (click)="setDay('Tomorrow')">Tomorrow</li>
                <li nz-menu-item (click)="setDay('After tomorrow')">After tomorrow</li>
            </ul>
        </nz-dropdown-menu> -->
    </div>
    <div style="float:right;min-width:100px; padding: 10px;text-align: right;padding-right:0px;">
        <!-- <button nz-button nzType="default" (click)="autoSetUntilSunset()">Set until sunset</button> -->
    </div>
    <div style="clear:both;"></div>

    <nz-slider (nzOnAfterChange)="sliderTimeChanged($event)" [nzMarks]="marksTime" nzStep="100" [ngModel]="[selectedFrom,selectedTo]" nzRange nzMin="900" nzMax="2300" [nzTipFormatter]="formatterTime"></nz-slider>

    Distance from {{ originName }}
    <!-- <strong>Riga</strong> (default) -->

    <!-- <nz-select [(ngModel)]="origin" nzAllowClear nzPlaceHolder="Choose" nzShowSearch style="margin-left:10px;width:150px;">
        <nz-option nzValue="Riga" nzLabel="Riga"></nz-option>
    </nz-select> -->

    <!-- Zorro autocomplete controller maybe here -->
    <!-- <button nz-button nzType="default" nzShape="circle"><mat-icon matSuffix>search</mat-icon></button> -->
    <button style="margin-left: 2px;" nz-button nzType="primary" nzSize="default" nzShape="circle" (click)="setCurrentLocationOrigin()"><i class='bx bx-map nav__icon'></i></button>

    <!-- <a class="nav__link" [class.active-link]="activeWindow === OpenViewEnum.List" (click)="navList()">
        <i class='bx bx-list-ul nav__icon'></i>
        <span class="nav__name">List</span>
    </a> -->

    <!-- <nz-slider [nzMarks]="marksDistance" nzStep="10" [ngModel]="100" [nzMax]="200" [nzTipFormatter]="formatterDistance"></nz-slider> -->

    <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksDistanceMinutes" nzMin="10" nzStep="10" [(ngModel)]="distanceMin" [nzMax]="180" [nzTipFormatter]="formatterDistanceMinutes"></nz-slider>

    <nz-divider id="filter-divider" nzText="" nzOrientation="right"></nz-divider>

    <div style="text-align: right;">
        Enable all filters
        <nz-switch (ngModelChange)="toggleFiltersEnabled($event)" [(ngModel)]="isAllFiltersEnabled"></nz-switch>
    </div>

    <div style="float:left;width:50%;">
        Temperature
    </div>
    <div style="float:right;width:50%;text-align: right;">
        <nz-switch [nzDisabled]="!isAllFiltersEnabled" (ngModelChange)="isTemperatureFilterEnabled = $event; goEmit()" [(ngModel)]="isTemperatureFilterEnabled" nzSize="small"></nz-switch>
    </div>
    <div style="clear:both;"></div>

    <nz-slider (nzOnAfterChange)="sliderTemperatureChanged($event)" [nzMarks]="marksTemperature" [ngModel]="[minTemperature, maxTemperature]" nzRange [nzMax]="35" [nzTipFormatter]="formatter" [nzDisabled]="!isTemperatureFilterEnabled || !isAllFiltersEnabled"></nz-slider>

    <div style="float:left;width:50%;">
        Max cloud cover (0-100%)
    </div>
    <div style="float:right;width:50%;text-align: right;">
        <nz-switch [nzDisabled]="!isAllFiltersEnabled" (ngModelChange)="isCloudsFilterEnabled = $event; goEmit()" [(ngModel)]="isCloudsFilterEnabled" nzSize="small"></nz-switch>
    </div>
    <div style="clear:both;"></div>

    <div style="float:left;width:33%;min-width:100px;">
        <img class="outline-icon" src="assets/svg/Sun.svg" alt="sunny" style="width:35px;" />
    </div>
    <div style="float:left;width:33%;min-width:100px;text-align: center;">
        <img class="outline-icon" src="assets/svg/Cloud-Sun.svg" alt="cloud and sun" style="width:35px;" />
    </div>
    <div style="float:right;width:33%;min-width:100px;text-align: right;">
        <img class="outline-icon" src="assets/svg/Cloud.svg" alt="cloudy" style="width:35px;" />
    </div>
    <div style="clear:both;"></div>
    <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksPercentClouds" nzStep="10" [(ngModel)]="maxClouds" [nzMax]="100" [nzTipFormatter]="formatterPercent" [nzDisabled]="!isCloudsFilterEnabled || !isAllFiltersEnabled"></nz-slider>

    <div style="float:left;width:70%;min-width:200px;">
        Max wind (km/h)&nbsp;<img class="outline-icon" src="assets/svg/Wind.svg" alt="wind" style="width:35px;" />
    </div>
    <div style="float:right;width:30%;min-width:100px;text-align: right;">
        <nz-switch [nzDisabled]="!isAllFiltersEnabled" (ngModelChange)="isWindFilterEnabled = $event; goEmit()" [(ngModel)]="isWindFilterEnabled" nzSize="small"></nz-switch>
    </div>
    <div style="clear:both;"></div>

    <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksWind" nzStep="3" [(ngModel)]="maxWind" [nzMax]="42" [nzTipFormatter]="formatterWind" [nzDisabled]="!isWindFilterEnabled || !isAllFiltersEnabled"></nz-slider>

    <div style="float:left;width:85%;min-width:200px;">
        Max chance of rain/snow (0-100%)&nbsp;<img class="outline-icon" src="assets/svg/Cloud-Drizzle.svg" alt="rain" style="width:35px;" />
    </div>
    <div style="float:right;width:15%;text-align: right;">
        <nz-switch [nzDisabled]="!isAllFiltersEnabled" (ngModelChange)="isRainFilterEnabled = $event; goEmit()" [(ngModel)]="isRainFilterEnabled" nzSize="small"></nz-switch>
    </div>
    <div style="clear:both;"></div>

    <nz-slider (nzOnAfterChange)="goEmit()" [nzMarks]="marksPercentRain" nzStep="10" [(ngModel)]="maxRain" [nzMax]="100" [nzTipFormatter]="formatterPercent" [nzDisabled]="!isRainFilterEnabled || !isAllFiltersEnabled"></nz-slider>

    <nz-divider id="filter-divider" nzText="" nzOrientation="right"></nz-divider>

    <div style="float:left;">
        
    <button nz-button nzType="default" (click)="autoSet()" nzSize="small"
        nz-tooltip
        nzTooltipTitle="Updates all filters with best options available for the next 2 days"
        nzTooltipPlacement="topLeft"
        >Auto set filters</button>

    <span nz-icon nzType="info-circle" nzTheme="outline"
        nz-tooltip
        nzTooltipTitle="Updates all filters with best options available for the next 2 days"
        nzTooltipPlacement="topLeft"
        [nzTooltipTrigger]="'click'"
        style="margin-left:5px;"></span>
    </div>

    <div style="float:right;">
    Auto set every day
    <nz-switch [(ngModel)]="isAutoUpdateEnabled" nzSize="small"></nz-switch>
    </div>

    <div style="clear: both;padding-bottom:15px;"></div>

</div>