
<div style="height:50px;font-size:24px;margin-top:10px">
    <div>
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixIconButton">
          <nz-select
          nzSize="large"
          nzMode="default"
          nzPlaceHolder="city name, country code"
          nzShowSearch
          nzServerSearch
          [(ngModel)]="selectedPlace"
          (nzOnSearch)="onSearch($event)"
          (nzOpenChange)="onSearch('')"
          (ngModelChange)="loadSelectedPlace()">
            <ng-container *ngFor="let o of optionList">
              <nz-option *ngIf="!isSearchLoading" [nzValue]="o" [nzLabel]="o.name"></nz-option>
            </ng-container>

            <nz-option *ngIf="isSearchLoading" nzDisabled nzCustomContent>
              <span nz-icon nzType="loading" class="loading-icon"></span>
              Loading Data...
            </nz-option>
          </nz-select>

        </nz-input-group>
        <ng-template #suffixIconButton>
          <button nz-button nzType="primary" nzSize="large" (click)="loadSelectedPlace(true)">
            <span nz-icon nzType="reload" nzTheme="outline"></span>
          </button>
          <button *ngIf="fullDetails" nz-button nzType="default" nzSize="large" style="margin-left: 2px;" (click)="toggleFav()">
            <span nz-icon nzType="star" [nzTheme]="isFav() ? 'fill' : 'outline'"></span>
          </button>
        </ng-template>
      </div>
</div>

<loader [loading]="loading" [loadingFailed]="loadingFailed"></loader>

<nz-tabset [nzCentered]="true" *ngIf="!loading" [nzTabPosition]="'top'" style="height:calc(100% - 4rem);padding-bottom:10px;"
  [(nzSelectedIndex)]="currentTabIndex"
  >
  <!-- (swiperight)="swipeRight()"
  (swipeleft)="swipeLeft()" -->

    <nz-tab [nzTitle]="'Today'" *ngIf="fullDetails && fullDetails.today">
      <details-view-day-tab [fullDetails]="fullDetails"
        [day]="TodayTomorrow.Today"></details-view-day-tab>
      <div class="bottom-spacer"></div>
    </nz-tab>
    <nz-tab [nzTitle]="'Tomorrow'" *ngIf="fullDetails && fullDetails.tomorrow">
      <details-view-day-tab [fullDetails]="fullDetails"
        [day]="TodayTomorrow.Tomorrow"></details-view-day-tab>
      <div class="bottom-spacer"></div>
    </nz-tab>
    <nz-tab [nzTitle]="'15 days'" *ngIf="fullDetails && fullDetails.forecast8DaysMinimal !== null">
      <details-view-day-list-tab (load15day)="loadSelectedPlace(true)" [fullDetails]="fullDetails"></details-view-day-list-tab>
      <div class="bottom-spacer"></div>
    </nz-tab>
    <nz-tab [nzTitle]="'Nature trails'" *ngIf="fullDetails && fullDetails.pointsOfInterest">
      <div class="poi-outer-container">
        <!-- <div class="poi-filter-container">
            <mat-form-field class="input-element">
                <mat-label>Points of interest within distance:</mat-label>
                <input
                    matInput
                    type="number"
                    [(ngModel)]="poiDistanceKm"/>
                <span matSuffix>&nbsp;km</span>
                <mat-icon matSuffix>hiking</mat-icon>
            </mat-form-field>
            <button
                class="filterPoisButton"
                mat-raised-button
                color="primary"
                (click)="findPois()">
                GO
            </button>
        </div> -->

        <!-- <div id="loading-window" [hidden]="!loading">
            <img *ngIf="!loadingFailed"
                src="assets/loading.gif"
                alt="loading">
            <img *ngIf="loadingFailed"
                src="assets/loadingFailed.png"
                alt="loading">
        </div> -->

        <div class="poi-list-container">
            <div *ngFor="let poi of fullDetails.pointsOfInterest" class="poi-container">
                <div class="poi-title">
                    <strong>
                        <a target="_blank" [href]="poi.link">{{ poi.name }} - {{ poi.distanceFromCity }} from {{ fullDetails.placeName }}</a>
                    </strong>
                </div>

                <ng-container *ngIf="poi.picture != ''">
                    <div class="poi-box-with-picture">
                        <div class="poi-picture"><img src="{{ poi.picture }}"/></div>

                        <div class="poi-description-with-picture">
                            <span *ngIf="poi.type !== ''" class="poi-type"><strong>{{ poi.type }}</strong></span>
                            <span [innerHTML]="poi.description"></span>
                        </div>
                        
                        <div class="clear"></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="poi.picture == ''">
                    <div class="poi-box-no-picture">
                        <span class="poi-type"><strong>{{ poi.type }}</strong></span>
                        <span [innerHTML]="poi.description"></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="bottom-spacer"></div>
    </nz-tab>
</nz-tabset>