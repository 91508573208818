import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { CommonFilters, GoArgs, TimeRangeFilters } from '../models/Args';
import { FavService } from '../services/fav.service';
import { NzMarks, NzSliderValue } from 'ng-zorro-antd/slider';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { updateState } from '../store/common-filters.actions';
import { Place } from '../models/generated/Place.generated';
import { FiltersState } from '../store/common-filters.reducer';
import { ExplorationService } from '../services/exploration.service';
import { Moment } from 'moment';
import { SuggestedFilters } from '../models/generated/SuggestedFilters.generated';
import { CommonModule } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import { CustomPlace } from '../models/Constants';
import { openDetails } from '../store/details.actions';
import { last } from 'rxjs/operators';

@Component({
  selector: 'filters-window',
  templateUrl: './filters-window.component.html',
  styleUrls: ['./filters-window.component.css']
})
export class FiltersWindowComponent implements OnInit {
  // * All values temporary to not break model until init load succeeds:
  isAutoUpdateEnabled: boolean = true;
  lastAutoUpdateIsoDateTimeUtc: string = moment().utc().add(-1, 'day').toISOString();
  sunriseIsoDateTime: string = moment("9:00", "H:mm").toISOString();
  sunsetIsoDateTime: string = moment("18:00", "H:mm").toISOString();

  distanceMin: number = 120;
  minTemperature: number = 17
  maxTemperature: number = 26
  maxClouds: number = 60;
  maxWind: number = 18;
  maxRain: number = 40;

  isAllFiltersEnabled: boolean = false;
  isTemperatureFilterEnabled: boolean = true;
  isCloudsFilterEnabled: boolean = true;
  isWindFilterEnabled: boolean = true;
  isRainFilterEnabled: boolean = true;

  selectedDay: string = "today";
  selectedFrom: number = 900;
  selectedTo: number = 1800;
  originName: string = "origin";

  timeRangeFilters: TimeRangeFilters =
  {
    selectedDay: 'today',
    selectedFromToday: 900,
    selectedToToday: 1800,
    selectedFromTomorrow: 900,
    selectedToTomorrow: 1800,
    selectedFromAfterTomorrow: 900,
    selectedToAfterTomorrow: 1800,
  };

  disableGoAndShowLoading: boolean = false;
  loadingFailed: boolean = false;

  @Output() go = new EventEmitter<GoArgs>();

  commonFilters$: Observable<FiltersState>;

  debouncedGo: (model: CommonFilters) => void;

  constructor(private favService: FavService, private explorationService: ExplorationService, private store: Store<{ commonFilters: FiltersState }>) {
    this.debouncedGo = this.debounce((model: CommonFilters) => {
      this.store.dispatch(updateState({
        filtersState: { commonFilters: model }
      }));

      this.saveStateToLocalStorage(model);
      this.go.emit(this.buildGoArgsFromCommonFiltersModel(model));
    }, 1500);

    this.commonFilters$ = store.select('commonFilters');
  }

  buildGoArgsFromCommonFiltersModel(model: CommonFilters): GoArgs {
    let goModel: GoArgs;

    let selectedFrom = model.timeRangeFilters.selectedFromToday;
    let selectedTo = model.timeRangeFilters.selectedToToday;

    if (model.timeRangeFilters.selectedDay === "tomorrow") {
      selectedFrom = model.timeRangeFilters.selectedFromTomorrow;
      selectedTo = model.timeRangeFilters.selectedToTomorrow;
    }
    else if  (model.timeRangeFilters.selectedDay === "after-tomorrow") {
      selectedFrom = model.timeRangeFilters.selectedFromAfterTomorrow;
      selectedTo = model.timeRangeFilters.selectedToAfterTomorrow;
    }

    goModel = {
      origin: this.explorationService.getOrigin()!, // should be resolved by now (*crosses fingers*)
      distanceMin: model.distanceMin,
      favs: this.favService.getFavorites(),
      isAllFiltersEnabled: model.isAllFiltersEnabled,
      isCloudsFilterEnabled: model.isAllFiltersEnabled ? model.isCloudsFilterEnabled : false,
      isRainFilterEnabled:  model.isAllFiltersEnabled ? model.isRainFilterEnabled : false,
      isTemperatureFilterEnabled: model.isAllFiltersEnabled ? model.isTemperatureFilterEnabled : false,
      isWindFilterEnabled: model.isAllFiltersEnabled ? model.isWindFilterEnabled : false,
      maxClouds: model.maxClouds,
      maxRain: model.maxRain,
      maxTemperature: model.maxTemperature,
      maxWind: model.maxWind,
      minTemperature: model.minTemperature,
      selectedDay: model.timeRangeFilters.selectedDay,
      selectedFrom: moment(selectedFrom.toString(), 'hmm').format("H:mm"), //+selectedFrom.replace(':', ''),
      selectedTo: moment(selectedTo.toString(), 'hmm').format("H:mm"), //+selectedTo.replace(':', ''),
    }

    return goModel;
  }

  ngOnInit(): void {
    // Enabled in handleFiltersOnWebsiteOpen
    this.disableGoAndShowLoading = true;

    this.commonFilters$.subscribe((filters: FiltersState) => {
      if (filters.commonFilters === null) { // Comes when website opened
        this.handleFiltersOnWebsiteOpen();
      }
      else { // Filter update after website init has been done (from some other componenet or reflected)
        this.handleUpdateToFiltersFromOtherComponentAndReflection(JSON.parse(JSON.stringify(filters))!);
      }
    });

    // Check here if 1 day has passed and update using suggested filters (needed if website is not reloaded every 24h)
    // TODO: migrate to service worker approach with periodic bg sync - https://developer.chrome.com/articles/periodic-background-sync/
    setTimeout(() => {
      this.checkIfWebsiteOpenSinceYesterday();
    }, 60 * 60 * 1000); // 1h

    this.favService.requestingRefresh.subscribe(() => {
      this.goEmit();
      console.log("refresh requested")
    });
  }

  private checkIfWebsiteOpenSinceYesterday() {
    const localStorageState: CommonFilters | null = this.getSavedStateFromLocalStorage();

    if (localStorageState === null) {
      return;
    }        

    let lastExecutionTime = localStorageState.lastAutoUpdateIsoDateTimeUtc;
    let lastExecutionDay = moment(lastExecutionTime).utc().date();
    let currentDay = moment().utc().date();
    let has1dPassedSinceLastAutoUpdate = lastExecutionDay != currentDay;

    // We want to update only if 1d has passed
    if (has1dPassedSinceLastAutoUpdate) {
        this.handleFiltersOnWebsiteOpen();
    }

    // TODO: migrate to service worker approach with periodic bg sync - https://developer.chrome.com/articles/periodic-background-sync/
    setTimeout(() => {
      this.checkIfWebsiteOpenSinceYesterday();
    }, 60 * 60 * 1000); // 1h
  }

  private handleFiltersOnWebsiteOpen() {
    const localStorageState: CommonFilters | null = this.getSavedStateFromLocalStorage();

    this.explorationService.getSuggestedFilters().subscribe(suggestedFilters => {
      // Set region from server
      this.resolveRegionAndOrigin(suggestedFilters, localStorageState);

      const commonFiltersModel: CommonFilters = this.getCommonFiltersBasedOnLocalStorageAndSuggested(localStorageState, suggestedFilters);
      this.saveStateToLocalStorage(commonFiltersModel);

      this.setFiltersComponentMasterModel(commonFiltersModel);

      this.disableGoAndShowLoading = false;

      this.store.dispatch(updateState({
        filtersState: { commonFilters: this.getFiltersComponentMasterModel() }
      }));

      // First time, push go
      const filterModel = this.getFiltersComponentMasterModel();
      this.saveStateToLocalStorage(filterModel);
      this.go.emit(this.buildGoArgsFromCommonFiltersModel(filterModel));
    }, error => {
      this.loadingFailed = true;
      console.error("suggested filters loading failed:" + error);
    });
  }

  private resolveRegionAndOrigin(suggestedFilters: SuggestedFilters, localStorageState: CommonFilters | null) {
    this.explorationService.setRegionAndDefaultOrigin(suggestedFilters.region, suggestedFilters.regionDefaultOrigin);
    
    // Check if custom origin has been saved or not, if not use default
    if (localStorageState !== null && localStorageState.origin != null) {
      this.explorationService.setOrigin(localStorageState.origin);
    } else {
      this.explorationService.setOrigin(suggestedFilters.regionDefaultOrigin);

      // if default then prompt user for his location after 1 min timer simulate click on button
      const that = this;
      setTimeout(() => {
        if (that.explorationService.getOrigin()?.name !== CustomPlace) {
          that.setCurrentLocationOrigin();
        }
      }, 60 * 1000);
    }

    this.originName = this.explorationService.getOrigin()!.name === CustomPlace ? "your location" : this.explorationService.getOrigin()!.name;

    this.store.dispatch(openDetails({
      place: {name: this.originName, coords: this.explorationService.getOrigin()!.coords},
      switchView: false
    }));
  }

  private handleUpdateToFiltersFromOtherComponentAndReflection(filters: FiltersState) {
    const commonFilters = filters.commonFilters!;
    const that = this;

    if (hasIncomingModelChanges(commonFilters)) {
      this.distanceMin = commonFilters.distanceMin;

      const newOrigin: Place | null = that.explorationService.getOrigin();
      if (newOrigin != null && newOrigin.name === CustomPlace) {
        that.originName = "your location"; 
      }

      if (this.isAllFiltersEnabled !== commonFilters.isAllFiltersEnabled) {
        this.isAllFiltersEnabled = commonFilters.isAllFiltersEnabled;
      }

      this.timeRangeFilters = JSON.parse(JSON.stringify(commonFilters.timeRangeFilters));
      this.selectedDay = commonFilters.timeRangeFilters.selectedDay;
      if (commonFilters.timeRangeFilters.selectedDay == 'today') {
        this.selectedFrom = commonFilters.timeRangeFilters.selectedFromToday;
        this.selectedTo = commonFilters.timeRangeFilters.selectedToToday;
      }
      else if (commonFilters.timeRangeFilters.selectedDay == 'tomorrow') {
        this.selectedFrom = commonFilters.timeRangeFilters.selectedFromTomorrow;
        this.selectedTo = commonFilters.timeRangeFilters.selectedToTomorrow;
      } else {
        this.selectedFrom = commonFilters.timeRangeFilters.selectedFromAfterTomorrow;
        this.selectedTo = commonFilters.timeRangeFilters.selectedToAfterTomorrow;
      }

      const filterModel = this.getFiltersComponentMasterModel();
      this.saveStateToLocalStorage(filterModel);
      this.go.emit(this.buildGoArgsFromCommonFiltersModel(filterModel));
    }

    function hasIncomingModelChanges(filters: CommonFilters): boolean {
      let areChanges = that.isAllFiltersEnabled !== filters.isAllFiltersEnabled ||
        that.distanceMin !== filters.distanceMin ||
        that.timeRangeFilters.selectedDay !== filters.timeRangeFilters.selectedDay;

      areChanges = areChanges ||
        that.timeRangeFilters.selectedFromToday !== +filters.timeRangeFilters.selectedFromToday ||
        that.timeRangeFilters.selectedToToday !== +filters.timeRangeFilters.selectedToToday ||
        that.timeRangeFilters.selectedFromTomorrow !== +filters.timeRangeFilters.selectedFromTomorrow ||
        that.timeRangeFilters.selectedToTomorrow !== +filters.timeRangeFilters.selectedToTomorrow ||
        that.timeRangeFilters.selectedFromAfterTomorrow !== +filters.timeRangeFilters.selectedFromAfterTomorrow ||
        that.timeRangeFilters.selectedToAfterTomorrow !== +filters.timeRangeFilters.selectedToAfterTomorrow;

      areChanges = areChanges ||
        (that.originName !== CustomPlace && filters.origin?.name === CustomPlace)

      return areChanges;
    }
  }

  // * I think this actually should be stored in ngrx.... but it is here for now
  private getFiltersComponentMasterModel(): CommonFilters {
    return {
      origin: this.explorationService.getOrigin(),
      timeRangeFilters: this.timeRangeFilters,
      distanceMin: this.distanceMin >= 180 ? 360 : this.distanceMin,
      minTemperature: this.minTemperature,
      maxTemperature: this.maxTemperature,
      maxClouds: this.maxClouds,
      maxWind: this.maxWind,
      maxRain: this.maxRain,
      isAllFiltersEnabled: this.isAllFiltersEnabled,
      isTemperatureFilterEnabled: this.isTemperatureFilterEnabled,
      isCloudsFilterEnabled: this.isCloudsFilterEnabled,
      isWindFilterEnabled: this.isWindFilterEnabled,
      isRainFilterEnabled: this.isRainFilterEnabled,
      isAutoUpdateEnabled: this.isAutoUpdateEnabled,
      lastAutoUpdateIsoDateTimeUtc: this.lastAutoUpdateIsoDateTimeUtc,
      sunriseIsoDateTime: this.sunriseIsoDateTime,
      sunsetIsoDateTime: this.sunsetIsoDateTime,
    };
  }
  
  // * MASTER MODEL FOR FILTERS IS PLACED IN THIS COMPONENET AND NOT IN NGRX,
  // * NGRX ONLY CONTAINS THE UPDATES PART OF THE MODEL
  // * THIS COMPONENET IS THE SOURCE OF TRUTH
  setFiltersComponentMasterModel(model: CommonFilters) {
    this.isAutoUpdateEnabled = model.isAutoUpdateEnabled;
    this.lastAutoUpdateIsoDateTimeUtc = model.lastAutoUpdateIsoDateTimeUtc;
    this.sunriseIsoDateTime = model.sunriseIsoDateTime;
    this.sunsetIsoDateTime = model.sunsetIsoDateTime;

    this.minTemperature = model.minTemperature;
    this.distanceMin = model.distanceMin; 
    this.maxTemperature = model.maxTemperature;
    this.maxClouds = model.maxClouds;
    this.maxWind = model.maxWind;
    this.maxRain = model.maxRain;

    this.isAllFiltersEnabled = model.isAllFiltersEnabled;
    this.isTemperatureFilterEnabled = model.isTemperatureFilterEnabled;
    this.isCloudsFilterEnabled = model.isCloudsFilterEnabled;
    this.isWindFilterEnabled = model.isWindFilterEnabled;
    this.isRainFilterEnabled = model.isRainFilterEnabled;

    this.timeRangeFilters = model.timeRangeFilters;
    this.selectedDay = model.timeRangeFilters.selectedDay;

    if (this.timeRangeFilters.selectedDay === 'today') {
      this.selectedFrom = this.timeRangeFilters.selectedFromToday;
      this.selectedTo = this.timeRangeFilters.selectedToToday;
    }
    else if (this.timeRangeFilters.selectedDay === 'tomorrow') {
      this.selectedFrom = this.timeRangeFilters.selectedFromTomorrow;
      this.selectedTo = this.timeRangeFilters.selectedToTomorrow;
    } else {
      this.selectedFrom = this.timeRangeFilters.selectedFromAfterTomorrow;
      this.selectedTo = this.timeRangeFilters.selectedToAfterTomorrow;
    }
  }

  private getCommonFiltersBasedOnLocalStorageAndSuggested(localStorageState: CommonFilters | null, suggestedFilters: SuggestedFilters) {
    // Algorithm:
    // I. If no stored state then set ALL as suggested
    //  1. Save time of last update
    // II. If has stored state and auto update is disabled SET ONLY DAYLIGHT (if it is set more than 1d ago)
    //  1. Save time of last update
    // III. If has stored state and auto update enabled
    //  1. Get time of last update
    //  2. If it is more than 1d ago, set all filters
    //  3. Save time of last update
    
    let commonFiltersModel: CommonFilters;

    if (localStorageState === null) {
      // Save all as suggested
      commonFiltersModel = {
        lastAutoUpdateIsoDateTimeUtc: moment().utc().toISOString(),

        // default for first time:
        distanceMin: 120,
        isAllFiltersEnabled: false,
        isAutoUpdateEnabled: true,

        sunriseIsoDateTime: suggestedFilters.sunriseIsoDateTime,
        sunsetIsoDateTime: suggestedFilters.sunsetIsoDateTime,
        maxClouds: suggestedFilters.maxClouds,
        maxRain: suggestedFilters.maxRain,
        maxWind: suggestedFilters.maxWind,
        maxTemperature: suggestedFilters.maxTemperature,
        minTemperature: suggestedFilters.minTemperature,
        isTemperatureFilterEnabled: suggestedFilters.isTemperatureFilterEnabled,
        isCloudsFilterEnabled: suggestedFilters.isCloudsFilterEnabled,
        isRainFilterEnabled: suggestedFilters.isRainFilterEnabled,
        isWindFilterEnabled: suggestedFilters.isWindFilterEnabled,

        timeRangeFilters: this.calculateAutoTimeRangeFilters(
          suggestedFilters.sunriseIsoDateTime,
          suggestedFilters.sunsetIsoDateTime
        ),
        
        origin: this.explorationService.getOrigin()
      };
    }
    else { // local storage state is NOT NULL
      // Check if at least 1d has passed since last auto update
      let lastExecutionTime = localStorageState.lastAutoUpdateIsoDateTimeUtc;
      let lastExecutionDay = moment(lastExecutionTime).utc().date();
      let currentDay = moment().utc().date();
      let has1dPassedSinceLastAutoUpdate = lastExecutionDay != currentDay;

      commonFiltersModel = localStorageState;

      // We want to update only if 1d has passed
      if (has1dPassedSinceLastAutoUpdate) {
        if (localStorageState.isAutoUpdateEnabled) {
          commonFiltersModel = {
            origin: this.explorationService.getOrigin(),
            lastAutoUpdateIsoDateTimeUtc: moment().utc().toISOString(),

            // use saved:
            distanceMin: localStorageState.distanceMin,
            isAllFiltersEnabled: localStorageState.isAllFiltersEnabled,
            isAutoUpdateEnabled: localStorageState.isAutoUpdateEnabled,

            sunriseIsoDateTime: suggestedFilters.sunriseIsoDateTime,
            sunsetIsoDateTime: suggestedFilters.sunsetIsoDateTime,
            maxClouds: suggestedFilters.maxClouds,
            maxRain: suggestedFilters.maxRain,
            maxWind: suggestedFilters.maxWind,
            maxTemperature: suggestedFilters.maxTemperature,
            minTemperature: suggestedFilters.minTemperature,
            isTemperatureFilterEnabled: suggestedFilters.isTemperatureFilterEnabled,
            isCloudsFilterEnabled: suggestedFilters.isCloudsFilterEnabled,
            isRainFilterEnabled: suggestedFilters.isRainFilterEnabled,
            isWindFilterEnabled: suggestedFilters.isWindFilterEnabled,

            timeRangeFilters: this.calculateAutoTimeRangeFilters(
              suggestedFilters.sunriseIsoDateTime,
              suggestedFilters.sunsetIsoDateTime
            )
          };
        }
        else {
          // Still update sunrise/sunset fields (but no other, including dont set from/to)
          commonFiltersModel.sunriseIsoDateTime = suggestedFilters.sunriseIsoDateTime;
          commonFiltersModel.sunsetIsoDateTime = suggestedFilters.sunsetIsoDateTime;
        }
      }
    }

    return commonFiltersModel;
  }

  autoSet() {
    // ! warning! This is a workaround to get this auto update working, to remove this need refactor
    this.isAutoUpdateEnabled = true;

    const model = this.getSavedStateFromLocalStorage()!;
    model.lastAutoUpdateIsoDateTimeUtc = moment().utc().add(-2, 'days').toISOString();
    this.saveStateToLocalStorage(model);

    // simulate first open with outdated config and enabled auto update
    this.handleFiltersOnWebsiteOpen();
  }

  formatter(value: number): string {
    let prefix = "";
    if (value === 35) {
      prefix = ">";
    }
    else if (value === 0) {
      prefix = "<";
    }
    return prefix + `${value} C`;
  }

  formatterTime(value: number): string {
    const val = value.toString();
    return val.includes("000") ? val.replace("000", "0:00") : val.replace("00", ":00");
  }

  formatterDistance(value: number): string {
    return value.toString() + "km";
  }

  formatterPercent(value: number): string {
    return value.toString() + "%";
  }

  formatterDistanceMinutes(value: number): string {
    return value.toString() + (value === 180 ? "+" : "") + " mins";
  }

  formatterWind(value: number): string {
    return value.toString() + (value === 42 ? "+" : "") + " km/h";
  }

  toggleFiltersEnabled(newValue: boolean) {
    if (this.disableGoAndShowLoading) {
      return;
    }

    this.disableGoAndShowLoading = true;

    // this.isTemperatureFilterEnabled = newValue;
    // this.isCloudsFilterEnabled = newValue;
    // this.isWindFilterEnabled = newValue;
    // this.isRainFilterEnabled = newValue;
    this.isAllFiltersEnabled = newValue;

    this.disableGoAndShowLoading = false;
    this.goEmit();
  }

  sliderTimeChanged(param: NzSliderValue) {
    const model: number[] = param as number[];
    this.selectedFrom = model[0];
    this.selectedTo = model[1];

    // Weird read-only issue most likely related to angular componenet binding
    this.timeRangeFilters = JSON.parse(JSON.stringify(this.timeRangeFilters));

    if (this.selectedDay === 'today') {
      this.timeRangeFilters.selectedFromToday = this.selectedFrom;
      this.timeRangeFilters.selectedToToday = this.selectedTo;
    } else if (this.selectedDay === 'tomorrow') {
      this.timeRangeFilters.selectedFromTomorrow = this.selectedFrom;
      this.timeRangeFilters.selectedToTomorrow= this.selectedTo;
    } else {
      this.timeRangeFilters.selectedFromAfterTomorrow = this.selectedFrom;
      this.timeRangeFilters.selectedToAfterTomorrow = this.selectedTo;
    }

    this.goEmit();
  }

  sliderTemperatureChanged(param: NzSliderValue) {
    const model: number[] = param as number[];
    this.minTemperature = model[0];
    this.maxTemperature = model[1];

    this.goEmit();
  }

  selectedDayChanged(model: string) {
    this.selectedDay = model;

    // Weird read-only issue most likely related to angular componenet binding
    this.timeRangeFilters = JSON.parse(JSON.stringify(this.timeRangeFilters));

    this.timeRangeFilters.selectedDay = this.selectedDay;

    if (this.selectedDay === 'today') {
      this.selectedFrom = this.timeRangeFilters.selectedFromToday;
      this.selectedTo = this.timeRangeFilters.selectedToToday;
    } else if (this.selectedDay === 'tomorrow') {
      this.selectedFrom = this.timeRangeFilters.selectedFromTomorrow;
      this.selectedTo = this.timeRangeFilters.selectedToTomorrow;
    } else {
      this.selectedFrom = this.timeRangeFilters.selectedFromAfterTomorrow;
      this.selectedTo = this.timeRangeFilters.selectedToAfterTomorrow;
    }

    this.goEmit();
  }

  goEmit(): void {
    if (this.disableGoAndShowLoading) {
      return;
    }

    const filterModel = this.getFiltersComponentMasterModel();
    this.debouncedGo(filterModel);
  }

  debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
      if (timeout !== null) {
        clearTimeout(timeout);
        timeout = null;
      }
      timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
  };

  // private autoSetUntilSunset(): void {
  //   const model: TimeRangeFilters = 
  //     this.calculateAutoTimeRangeFilters(this.sunriseIsoDateTime, this.sunsetIsoDateTime);

  //   if (this.selectedDay == 'today') {
  //     this.selectedFrom = model.selectedFromToday
  //     this.selectedTo = model.selectedToToday
  //   }
  //   else { // same for after tomorrow
  //     this.selectedFrom = model.selectedFromTomorrow
  //     this.selectedTo = model.selectedToTomorrow
  //   }
  // }

  private calculateAutoTimeRangeFilters(sunriseIsoDateTime: string, sunsetIsoDateTime: string): TimeRangeFilters {
    const sunrise: Moment = keepOnlyHourPartOfDateTime(sunriseIsoDateTime);
    const sunset: Moment = keepOnlyHourPartOfDateTime(sunsetIsoDateTime).clone().add(1, 'hours');

    const selectedDay = moment().isBetween(sunrise, sunset.clone().add(-1, 'hours')) ? "today" : "tomorrow";

    // TODO: check if sunrise to sunset is less than 8h, then add 1h to sunset
    // TODO: also if sunrise is earlier than 9 probably reduce to 9
    const model: TimeRangeFilters = {
      selectedDay: selectedDay,
      selectedFromToday: +moment().format('HH00'),
      selectedToToday: +sunset.clone().add(0, 'hours').format('HH00'),
      selectedFromTomorrow: +sunrise.format('HH00'),
      selectedToTomorrow: +sunset.clone().add(0, 'hours').format('HH00'),
      selectedFromAfterTomorrow: +sunrise.format('HH00'),
      selectedToAfterTomorrow: +sunset.clone().add(0, 'hours').format('HH00'),
    }

    // check if for today from is after tomorrow
    if (moment().isAfter(sunset.clone().add(-1, "hours"))) {
      const hours3AfterNow = +moment().clone().add(3, 'hours').format('HH00');
      if (hours3AfterNow > 2300) {
        model.selectedFromToday = 2100;
        model.selectedToToday = 2300;
      } else {
        model.selectedFromToday = +moment().format('HH00');
        model.selectedToToday = hours3AfterNow;
      }
    }

    return model;

    function keepOnlyHourPartOfDateTime(isoDateTime: string): moment.Moment {
      return moment(moment(isoDateTime).format('HH00'), 'HH00');
    }
  }

  private getSavedStateFromLocalStorage(): CommonFilters | null {
    const savedFilterModel: string | null = localStorage.getItem("filterModel");

    if (savedFilterModel) {
      const data: CommonFilters = JSON.parse(savedFilterModel);
      return data;
    }
    else {
      return null;
    }
  }

  private saveStateToLocalStorage(filterModel: CommonFilters): void {
    localStorage.setItem("filterModel", JSON.stringify(filterModel));
  }

  setCurrentLocationOrigin() {
    const that = this;
    this.explorationService.setCurrentLocationOrigin(() => {
      const newOrigin: Place | null = that.explorationService.getOrigin();

      if (newOrigin != null && newOrigin.name === CustomPlace) {
        that.originName = "your location"; 
      }

      that.goEmit();
    });
  }

  marksTemperature: NzMarks = {
    0: {
      style: {
        color: '#48f'
      },
      label: '<strong><</strong> 0C'
    },
    6: `6C`,
    13: `13C`,
    17: `17C`,
    21: `21C`,
    26: `26C`,
    29: `29C`,
    35: {
      style: {
        color: '#f50'
      },
      label: '<strong>></strong>35'
    },
  };

  marksTime: NzMarks = {
    900: {
      // style: {
      //   color: '#05f'
      // },
      label: '9:00'
    },
    1100: `11:00`,
    1400: `14:00`,
    1700: `17:00`,
    2000: `20:00`,
    2300: {
      // style: {
      //   color: '#f50'
      // },
      label: '23:00'
    },
  };

  marksDistance: NzMarks = {
    0: {
      // style: {
      //   color: '#05f'
      // },
      label: '0km'
    },
    20: `20`,
    40: `40km`,
    70: `70`,
    100: `100km`,
    200: {
      // style: {
      //   color: '#f50'
      // },
      label: '200+'
    },
  };

  marksDistanceMinutes: NzMarks = {
    10: {
      // style: {
      //   color: '#05f'
      // },
      label: '10 mins'
    },
    30: `30`,
    60: `60 mins`,
    90: `90`,
    120: `120 mins`,
    180: {
      // style: {
      //   color: '#f50'
      // },
      label: '180+'
    },
  };

  marksPercentClouds: NzMarks = {
    0: {
      // style: {
      //   color: '#05f'
      // },
      label: '0%'
    },
    20: `20%`,
    40: `40%`,
    60: `60%`,
    80: `80%`,
    100: {
      // style: {
      //   color: '#f50'
      // },
      label: '100%'
    },
  };

  marksPercentRain: NzMarks = {
    0: {
      // style: {
      //   color: '#05f'
      // },
      label: '0%'
    },
    20: `20%`,
    40: `40%`,
    60: `60%`,
    80: `80%`,
    100: {
      // style: {
      //   color: '#f50'
      // },
      label: '100%'
    },
  };

  marksWind: NzMarks = {
    0: {
      // style: {
      //   color: '#05f'
      // },
      label: '0 km/h'
    },
    6: `6`,
    12: `12 km/h`,
    18: `18`,
    24: `24 km/h`,
    30: {
      // style: {
      //   color: '#f50'
      // },
      label: '30'
    },
    42: {
      // style: {
      //   color: '#f50'
      // },
      label: '42+'
    },
  };
}
