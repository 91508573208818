import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CommonFilters } from '../models/Args';
import { updateState } from '../store/common-filters.actions';
import { NzMarks, NzSliderValue } from 'ng-zorro-antd/slider';
import { ExplorationService } from '../services/exploration.service';
import { resetAndAddPois } from '../store/map.actions';
import { openDetails } from '../store/details.actions';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';
import { PointOfInterest } from '../models/generated/PointOfInterest.generated';
import { FiltersState } from '../store/common-filters.reducer';
import { Place } from '../models/generated/Place.generated';
import { CustomPlace } from '../models/Constants';
import { Region } from '../models/generated/Enum/Region.generated';

@Component({
  selector: 'weather-map',
  templateUrl: './weather-map.component.html',
  styleUrls: ['./weather-map.component.css']
})
export class WeatherMapComponent implements OnChanges, AfterViewInit  {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  // @ViewChild('placeInfoWindow', { static: true }) info!: MapInfoWindow;
  // @ViewChild('poiInfoWindow', { static: false }) poiInfo!: MapInfoWindow;

  @Input() expandedElement: WeatherForecastHourGroup | null = null;
  @Input() forecastGroups: WeatherForecastHourGroup[] = [];
  @Input() loading: boolean = false;
  @Input() useOpenWeather: boolean = false;

  // Map center
  lat: number = 0;
  lng: number = 0;

  scaledSizeMedium = new google.maps.Size(35, 35);
  scaledSizeBig = new google.maps.Size(45, 45);
  markerAnchor = new google.maps.Point(0, 45);
  isQuickFilterExpanded: boolean = false;
  expandIcon: string = this.isQuickFilterExpanded ? "up-circle" : "down-circle";
  mapShowPois: boolean = true;
  savedExpanded: WeatherForecastHourGroup | null = null;
  originName: string = "origin";
  currentZoomLevel: number = 8;

  // Common filters:
  commonFilters$: Observable<FiltersState>;
  isAllFiltersEnabled: boolean = false;
  distanceMin: number = 120;
  selectedDay: string = "today";
  selectedFrom: number = 1100;
  selectedTo: number = 2000;
  initialZoom: number = 8;

  quickDetailsContext: string = "map";

  pois$: Observable<PointOfInterest[]>; // Coming through place detail info
  pois: PointOfInterest[] = [];

  iconUrl: string = "assets/red-circle.png";
  hikingIconUrl: string = "assets/hiking.png";

  infoContent: WeatherForecastHourGroup | null = null;
  poiInfoContent: PointOfInterest | null = null;

  commonFiltersSaved: CommonFilters | null = null;

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    mapTypeControl: true,
    minZoom: 6,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
    },

  };

  constructor(private explorationService: ExplorationService,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ pois: PointOfInterest[], commonFilters: FiltersState }>) {
    this.pois$ = store.select('pois');

    this.pois$.subscribe((pois: PointOfInterest[]) => {
      this.pois = pois;
    });

    this.commonFilters$ = store.select('commonFilters');

    this.commonFilters$.subscribe((state: FiltersState) => {
      if (state.commonFilters === null) {
        console.log("weather map filter null");
        return;
      } else {
        this.infoContent = null;
        this.poiInfoContent = null;

        // Set map center on app load
        if (this.lat === 0 && this.lng === 0 && this.explorationService.getDefaultOrigin() !== null) {
          // TODO: need to refactor so that this logic uses something like below from server
          // const mapCenter = this.explorationService.getDefaultOrigin();
          // this.lat = mapCenter!.coords.lat;
          // this.lng = mapCenter!.coords.long;

          if (this.explorationService.getRegion() === Region.Latvia) {
            this.initialZoom = 6;
            this.lat = 56.977373;
            this.lng = 24.464719;
          }
          else if (this.explorationService.getRegion() === Region.Madeira) {
            this.initialZoom = 10;
            this.lat = 32.757588;
            this.lng = -17.003117;
          }
          // else if (this.explorationService.getRegion() === Region.LaPalma) {
          //   this.initialZoom = 10;
          //   this.lat = 28.651498;
          //   this.lng = -17.860630;
          // }
          else if (this.explorationService.getRegion() === Region.LaPalma) { // El hierro
            this.initialZoom = 11;
            this.lat = 27.734289; // 27.734289, -18.010665
            this.lng = -18.010665;
          }
          else {
            console.error("Region not defined in front end map componenet - unknown center and zoom")
          }
        }

        if (state.commonFilters.origin?.name === CustomPlace) {
          this.originName = "your location";
        }
        else {
          this.originName = state.commonFilters.origin?.name ?? "origin";
        }

        this.commonFiltersSaved = JSON.parse(JSON.stringify(state.commonFilters));

        this.isAllFiltersEnabled = this.commonFiltersSaved!.isAllFiltersEnabled;
        this.distanceMin = this.commonFiltersSaved!.distanceMin;
        this.selectedDay = this.commonFiltersSaved!.timeRangeFilters.selectedDay;

        if (this.selectedDay === 'today') {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromToday;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToToday;
        }
        else if (this.selectedDay === 'tomorrow') {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow;
        } else {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["expandedElement"] !== undefined && changes["expandedElement"].currentValue != this.savedExpanded) {
      this.savedExpanded = changes["expandedElement"].currentValue;
      if (this.savedExpanded) {
        this.openInfoWindow(this.savedExpanded!);
      }
    }
  }

  mapShowPoisAtThisLevel() {
    let minZoomLevel = 11;

    if (this.explorationService.getRegion() === Region.Latvia) {
      minZoomLevel = 9;
    }
    else if (this.explorationService.getRegion() === Region.Madeira) {
      minZoomLevel = 11;
    }
    else if (this.explorationService.getRegion() === Region.LaPalma) {
      minZoomLevel = 11;
    }

    return this.currentZoomLevel > minZoomLevel;
  }

  ngAfterViewInit() {
    this.maximizeMapContainer();
  }

  maximizeMapContainer() {
    return;

    // // We need to resize div which is internal to external component
    // const mapContainer = this.document.querySelector<HTMLElement>(".map-container");

    // if (mapContainer) {
    //   mapContainer.style.height = "calc(100vh - 4rem - 100px)"; 
    // }
  }

  minimizeMapContainer() {
    return;

    // // We need to resize div which is internal to external component
    // const mapContainer = this.document.querySelector<HTMLElement>(".map-container");

    // if (mapContainer) {
    //   mapContainer.style.height = "calc(100vh - 4rem - 205px)"; 
    // }
  }

  public expandedClicked() {
    this.isQuickFilterExpanded = !this.isQuickFilterExpanded;
    this.expandIcon = this.isQuickFilterExpanded ? "up-circle" : "down-circle";
  }

  formatterTime(value: number): string {
    const val = value.toString();
    return val.includes("000") ? val.replace("000", "0:00") : val.replace("00", ":00");
  }

  selectedDayChanged(model: string) {
    this.selectedDay = model;

    // if (!this.isQuickFilterExpanded) {
    //   this.isQuickFilterExpanded = true;
    //   this.expandIcon = "up-circle";
    // }

    this.commonFiltersSaved!.timeRangeFilters.selectedDay = this.selectedDay;

    if (this.selectedDay === 'today') {
      this.selectedFrom =  this.commonFiltersSaved!.timeRangeFilters.selectedFromToday;
      this.selectedTo =  this.commonFiltersSaved!.timeRangeFilters.selectedToToday;
    } else if (this.selectedDay === 'tomorrow') {
      this.selectedFrom = this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow;
      this.selectedTo = this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow;
    } else {
      this.selectedFrom = this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow;
      this.selectedTo = this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow;
    }

    this.goEmit();
  }

  sliderTimeChanged(param: NzSliderValue) {
    const model: number[] = param as number[];
    this.selectedFrom = model[0];
    this.selectedTo = model[1];

    if (this.selectedDay === 'today') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromToday = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToToday = this.selectedTo;
    } else if (this.selectedDay === 'tomorrow') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow= this.selectedTo;
    } else {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow = this.selectedTo;
    }

    this.goEmit();
  }

  goEmit() {
    if (this.commonFiltersSaved === null) {
      return;
    }

    this.infoContent = null;
    this.poiInfoContent = null;

    this.commonFiltersSaved!.isAllFiltersEnabled = this.isAllFiltersEnabled;
    this.commonFiltersSaved!.distanceMin = this.distanceMin;
    this.commonFiltersSaved!.timeRangeFilters.selectedDay = this.selectedDay;

    if (this.selectedDay === 'today') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromToday = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToToday = this.selectedTo;
    } else if (this.selectedDay === 'tomorrow') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow = this.selectedTo;
    } else {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow = this.selectedTo;
    }

    this.store.dispatch(updateState({
      filtersState: { commonFilters: this.commonFiltersSaved }
    }));
  }

  toggleFiltersEnabled(newValue: boolean) {
    this.isAllFiltersEnabled = newValue;
    this.goEmit();
  }

  mapClick() {
    this.infoContent = null;
    this.poiInfoContent = null;
    this.pois = [];
    this.expandedElement = null;

    this.maximizeMapContainer();
  }

  openInfoWindow(selected: WeatherForecastHourGroup) {
    this.minimizeMapContainer();

    this.poiInfoContent = null;
    this.infoContent = selected;
    this.expandedElement = selected;
    //this.info.open(marker);

    this.pois = [];

    this.explorationService.getPoiForPlace(selected.place, this.explorationService.getRegion() === Region.Latvia ? 30 : 7).subscribe(poiList => {
      this.store.dispatch(resetAndAddPois({
        pois: poiList
      }));
    }, error => {
      console.error("poi loading failed");
    });
  }

  openInfoWindowForPoi(marker: MapMarker, poi: PointOfInterest) {
    this.minimizeMapContainer();

    this.infoContent = null;
    this.poiInfoContent = poi;
    //this.poiInfo.open(marker);
  }

  poiLinkClicked(poi: PointOfInterest) {
    window.open(poi.link, "_blank");
  }

  openDetails(group: WeatherForecastHourGroup) {
    this.store.dispatch(openDetails({
      place: {
        coords: group.coords!,
        name: group.place,
      },
      switchView: true
    }));
  }

  zoomChanged() {
    this.currentZoomLevel = this.map.getZoom();
  }

  showHours(group: WeatherForecastHourGroup) {
    if (this.quickDetailsContext === 'map') {
      this.quickDetailsContext = "map-hours";
    } else {
      this.quickDetailsContext = "map";
    }
  }

  getZIndexForMarker(markerAssignedForecastGroup: WeatherForecastHourGroup | null) {
    if (markerAssignedForecastGroup) {
      return this.expandedElement != null && this.expandedElement.place === markerAssignedForecastGroup.place ? 999 : 
        (markerAssignedForecastGroup.isFav ? 100 : 1);
    }

    return 1;
  }

  getSizeForMarker(markerAssignedForecastGroup: WeatherForecastHourGroup | null) {
    if (markerAssignedForecastGroup) {
      return this.expandedElement != null && this.expandedElement.place === markerAssignedForecastGroup.place ? this.scaledSizeBig : this.scaledSizeMedium;
    }

    return this.scaledSizeMedium;
  }

  getIconForMarker(markerAssignedForecastGroup: WeatherForecastHourGroup | null) {
    if (markerAssignedForecastGroup) {
      return this.expandedElement != null && this.expandedElement.place === markerAssignedForecastGroup.place ?
      markerAssignedForecastGroup!.iconFullUrl.replace('weather-icons-transparent', 'weather-icons-focused') : 
        (markerAssignedForecastGroup.isFav ? 
          markerAssignedForecastGroup!.iconFullUrl.replace('weather-icons-transparent', 'weather-icons-favs') : markerAssignedForecastGroup!.iconFullUrl.replace('weather-icons-transparent', 'weather-icons-bordered'));
    }

    return this.iconUrl;
  }

  getIconForPoi(poi: PointOfInterest) : google.maps.Icon {
    return {
      url: this.hikingIconUrl,
      scaledSize:  new google.maps.Size(22, 22),
    };
  }

  formatterDistanceMinutes(value: number): string {
    return value.toString() + (value === 180 ? "+" : "") + " mins";
  }

  setCurrentLocationOrigin() {
    const that = this;
    this.explorationService.setCurrentLocationOrigin(() => {
      const newOrigin: Place | null = that.explorationService.getOrigin();

      if (newOrigin != null && newOrigin.name === CustomPlace) {
        that.originName = "your location"; 
      }

      this.commonFiltersSaved!.origin = this.explorationService.getOrigin();

      that.goEmit();
    });
  }

  reload() {
    location.reload();
  }

  marksDistanceMinutes: NzMarks = {
    10: {
      // style: {
      //   color: '#05f'
      // },
      label: '10 min'
    },
    30: `30`,
    60: `60 min`,
    90: `90`,
    120: `120 min`,
    180: {
      // style: {
      //   color: '#f50'
      // },
      label: '180+'
    },
  };

  marksTime: NzMarks = {
    900: {
      // style: {
      //   color: '#05f'
      // },
      label: '9:00'
    },
    1100: `11:00`,
    1400: `14:00`,
    1700: `17:00`,
    2000: `20:00`,
    2300: {
      // style: {
      //   color: '#f50'
      // },
      label: '23:00'
    },
  };
}
