import { createAction, props } from '@ngrx/store';
import { PointOfInterest } from '../models/generated/PointOfInterest.generated';

export const addPois = createAction(
    '[POIS] Add pois',
    props<{ pois: PointOfInterest[]; }>()
);

export const resetPois = createAction(
    '[POIS] Reset pois'
);

export const resetAndAddPois = createAction(
    '[POIS] Reset and add pois',
    props<{ pois: PointOfInterest[]; }>()
);