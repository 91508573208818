import { Injectable, NgModule } from '@angular/core';
import { BrowserModule} from '@angular/platform-browser'; // HammerModule
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExploreComponent } from './explore/explore.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { SafePipe } from './services/safe.pipe';
import { WeatherMapComponent } from './weather-map/weather-map.component';
import { PlaceQuickDetailsComponent } from './place-quick-details/place-quick-details.component';
import { FiltersWindowComponent } from './filters-window/filters-window.component';
import { WeatherTableComponent } from './weather-table/weather-table.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { StoreModule } from '@ngrx/store'
import { mapReducer } from './store/map.reducer';
import { detailsReducer } from './store/details.reducer';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import lv from '@angular/common/locales/lv';
import { NzSliderModule } from "ng-zorro-antd/slider";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgZorroAntdModule } from './ng-zorro.module';
import { commonFiltersReducer } from './store/common-filters.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DetailsViewComponent } from './details-view/details-view.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { LoaderComponent } from './loader/loader.component';
import { WeatherHoursComponent } from './weather-hours/weather-hours.component';
import { DetailsViewDayTabComponent } from './details-view-day-tab/details-view-day-tab.component';
import { DetailsViewDayListTabComponent } from './details-view-day-list-tab/details-view-day-list-tab.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

registerLocaleData(lv);

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'swipe': {
            // override default settings
            velocity: 0.4,
            threshold: 20
        }
    }
}

import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';

const ngZorroConfig: NzConfig = {
  notification: { nzBottom: 80, nzPlacement: 'bottomLeft' }
};

@NgModule({
  declarations: [
    AppComponent,
    ExploreComponent,
    SafePipe,
    WeatherMapComponent,
    PlaceQuickDetailsComponent,
    FiltersWindowComponent,
    WeatherTableComponent,
    DetailsViewComponent,
    LoaderComponent,
    WeatherHoursComponent,
    DetailsViewDayTabComponent,
    DetailsViewDayListTabComponent,
  ],
  imports: [
    //HammerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    GoogleMapsModule,
    NzSliderModule,
    NzDropDownModule,
    NzIconModule,
    NzButtonModule,
    NzDividerModule,
    NzInputModule,
    NzSwitchModule,
    NzRadioModule,
    NzSelectModule,
    NzTableModule,
    NgZorroAntdModule,
    NzTabsModule,
    NzNotificationModule,
    StoreModule.forRoot({ pois: mapReducer, commonFilters: commonFiltersReducer, details: detailsReducer }),
      !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [CookieService, { provide: NZ_I18N, useValue: en_US }, { provide: NZ_ICONS, useValue: icons },
    { provide: NZ_CONFIG, useValue: ngZorroConfig }
//     ,{
//     provide: HAMMER_GESTURE_CONFIG,
//     useClass: MyHammerConfig
// }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
