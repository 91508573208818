import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ReplaySubject } from 'rxjs';
import { Place } from '../models/generated/Place.generated';

@Injectable({
  providedIn: 'root'
})
export class FavService {

  private favsDefault: Place[] = [ {name: "Riga", coords: { lat: 56.9496487, long: 24.1051865 }} ]

  public requestingRefresh: ReplaySubject<boolean> = new ReplaySubject();

  public getFavorites(): Place[] {
    var favsJson = localStorage.getItem('favs');
    let favs: Place[] | null = favsJson !== null ? JSON.parse(favsJson) : null;

    if (favs === null) {
      localStorage.setItem('favs', JSON.stringify(this.favsDefault));
    }

    return favs ?? this.favsDefault;
  }

  public addFavorite(place: Place): void {
    let favs = this.getFavorites();

    favs = favs.concat(place)

    localStorage.setItem('favs', JSON.stringify(favs));
    this.requestingRefresh.next(true);
  }

  public removeFavorite(place: Place): void {
    let favs = this.getFavorites();

    var toRemoveIndex = favs.findIndex(element => element.name === place.name 
      && element.coords.lat === place.coords.lat && element.coords.long === place.coords.long);

    if (toRemoveIndex != -1) {
      favs.splice(toRemoveIndex, 1);
    }

    localStorage.setItem('favs', JSON.stringify(favs));
    this.requestingRefresh.next(true);
  }

  public setAllFavorites(allFavs: Place[]): void {
    localStorage.setItem("favs", JSON.stringify(allFavs));
  }
}
