import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { DetailsPageComponent } from './pages/details-page/details-page.component';
// import { FilterPageComponent } from './pages/filter-page/filter-page.component';
// import { ListPageComponent } from './pages/list-page/list-page.component';
// import { MapPageComponent } from './pages/map-page/map-page.component';

const routes: Routes = [
  // { path: 'filter', component: FilterPageComponent },
  // { path: 'list', component: ListPageComponent },
  // { path: 'map', component: MapPageComponent },
  // { path: 'details', component: DetailsPageComponent },
  // { path: '', redirectTo: '/filter', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
