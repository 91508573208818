import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetForecastGroupsArgs } from '../models/Args';
import { Observable, of } from 'rxjs';
import { isDevMode } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { RegionForecast } from '../models/generated/RegionForecast.generated';
import { PointOfInterest } from '../models/generated/PointOfInterest.generated';
import { Coords } from '../models/generated/Coords.generated';
import { WeatherForecastPlaceFullDetail } from '../models/generated/WeatherForecastPlaceFullDetail.generated';
import { Place } from '../models/generated/Place.generated';
import { Region } from '../models/generated/Enum/Region.generated';
import { SuggestedFilters } from '../models/generated/SuggestedFilters.generated';
import { PlaceQuickDetailsComponent } from '../place-quick-details/place-quick-details.component';
import { CustomPlace } from '../models/Constants';

@Injectable({
  providedIn: 'root'
})
export class ExplorationService {
  apiHost = "";

  private region: Region = Region.Madeira; // default, will be set from server using host
  private origin: Place | null = null; // default, will be set from server using host
  private defaultOrigin: Place | null = null; // recieved from server, used for map center

  getRegion() : Region {
    return this.region;
  }

  getDefaultOrigin() : Place | null {
    return this.defaultOrigin;
  }

  getOrigin() : Place | null {
    return this.origin;
  }

  setRegionAndDefaultOrigin(region: Region, defaultOrigin: Place) {
    this.region = region;
    this.defaultOrigin = defaultOrigin;
  }

  setOrigin(place: Place) {
    this.origin = place;
  }

  setCurrentLocationOrigin(callbackSuccess: Function) {
    const that = this;
    navigator.geolocation.getCurrentPosition((geo: GeolocationPosition) => {
      that.setOrigin({
        name: CustomPlace,
        coords: {
          lat: geo.coords.latitude,
          long: geo.coords.longitude,
        }
      });

      callbackSuccess();
    });
  }

  constructor(private http: HttpClient) {
    if (window.location.href.includes('localhost')) {
      this.apiHost = "http://localhost:50598";
    }
  }

  getForecastGroups(args: GetForecastGroupsArgs): Observable<RegionForecast> {
    let params = new HttpParams().set("distance", args.distance.toString())
      .set("region", this.getRegion().toString())
      .set("originName", args.origin.name.toString())
      .set("originLat", args.origin.coords.lat.toString())
      .set("originLong", args.origin.coords.long.toString())
      .set("minAvgTemperature", args.minAvgTemperature.toString())
      .set("maxAvgTemperature", args.maxAvgTemperature.toString())
      .set("minAvgClouds", args.minAvgClouds.toString())
      .set("dateFrom", args.dateFrom)
      .set("dateTo", args.dateTo)
      .set("maxWind", args.maxWind.toString())
      .set("maxRain", args.maxRain.toString())
      .set("favs", args.favs.map(fav => fav.name).reduce((prev, curr) => prev + ", " + curr, ""))
      .set("isAllFiltersEnabled", args.isAllFiltersEnabled.toString())
      .set("isTemperatureFilterEnabled", args.isTemperatureFilterEnabled.toString())
      .set("isCloudsFilterEnabled", args.isCloudsFilterEnabled.toString())
      .set("isWindFilterEnabled", args.isWindFilterEnabled.toString())
      .set("isRainFilterEnabled", args.isRainFilterEnabled.toString())

    return this.http.get<RegionForecast>(this.apiHost + "/weather-forecasts", { params: params });
  }

  getPoiForPlace(placeName: string, distance: number): Observable<PointOfInterest[]> {
    // Need to add a cache here
    let params = new HttpParams().set("place", placeName)
      .set("distance", distance.toString())
      .set("region", this.region.toString())

    return this.http.get<PointOfInterest[]>(this.apiHost + "/point-of-interest", { params: params });
  }

  getPlaceDetails(place: Place, ignoreCache: boolean): Observable<WeatherForecastPlaceFullDetail> {
    // cant modify place.coords as it is read only though ngrx
    let currentCoords;
    if (place.name === "your location") {
      currentCoords = this.getOrigin()!.coords;
    }
    else {
      currentCoords = place.coords;
    }

    let params = new HttpParams().set("placeName", place.name)
      .set("lat", currentCoords.lat.toString())
      .set("long", currentCoords.long.toString())
      .set("ignoreCache", ignoreCache.toString())
      .set("region", this.region.toString())

    return this.http.get<WeatherForecastPlaceFullDetail>(this.apiHost + "/place-details", { params: params });
  }

  getPlaceSearchResults(placeName: string, favs: Place[]): Observable<Place[]> {
    if (placeName === "") {
      return of(favs);
    }

    let params = new HttpParams().set("placeName", placeName)
      .set("region", this.region.toString());

    return this.http.post<Place[]>(this.apiHost + "/place-search-results", favs, { params: params });
  }

  getSuggestedFilters(): Observable<SuggestedFilters> {
    return this.http.get<SuggestedFilters>(this.apiHost + "/suggested-filters");
  }

  isStandalone(): boolean {
    // Check if running in standalone mode on other platforms (e.g., Android)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }

    return false;
  }

  isMobileScreenWidth(maxMobileWidth: number = 950): boolean {
    return window.innerWidth <= maxMobileWidth;
  }

  async isRelatedAppInstalled(relatedAppId: string): Promise<boolean> {
    if ('getInstalledRelatedApps' in window.navigator) {
      try {
        const relatedApps = await (window.navigator as any).getInstalledRelatedApps();
        return relatedApps.some((app: any) => app.id === relatedAppId);
      } catch (error) {
        console.error('Error checking for related apps:', error);
        return false;
      }
    }
  
    return false;
  }

}
