<nav>
    <div class="nav__menu" id="nav-menu">
        <ul class="nav__list">
            <li class="nav__item">
                <a class="nav__link" [class.active-link]="activeWindow === OpenViewEnum.Filter" (click)="navFilter()">
                    <i class='bx bx-filter-alt nav__icon'></i>
                    <span class="nav__name">Filter</span>
                </a>
            </li>

            <li class="nav__item">
                <a class="nav__link" [class.active-link]="activeWindow === OpenViewEnum.List" (click)="navList()">
                    <i class='bx bx-list-ul nav__icon'></i>
                    <span class="nav__name">List</span>
                </a>
            </li>

            <li class="nav__item">
                <a class="nav__link" [class.active-link]="activeWindow === OpenViewEnum.Map" (click)="navMap()">
                    <i class='bx bx-map-alt nav__icon'></i>
                    <span class="nav__name">Map</span>
                </a>
            </li>

            <li class="nav__item">
                <a class="nav__link" [class.active-link]="activeWindow === OpenViewEnum.Details" (click)="navDetails()">
                    <i class='bx bx-map nav__icon'></i>
                    <span class="nav__name">Location</span>
                </a>
            </li>

        </ul>
    </div>
</nav>

<main [ngClass]="{'container-filters': activeWindow === OpenViewEnum.Filter, 'container-list': activeWindow === OpenViewEnum.List, 
                    'container-details': activeWindow === OpenViewEnum.Details, 'container-map': activeWindow === OpenViewEnum.List}">

    <section class="mobile container section section-height" id="home">
        <filters-window (go)="go($event)" [hidden]="activeWindow !== OpenViewEnum.Filter"></filters-window>
        
        <loader [loading]="loading && (activeWindow === OpenViewEnum.List || activeWindow === OpenViewEnum.Map)" [loadingFailed]="loadingFailed"></loader>

        <weather-table [hidden]="activeWindow !== OpenViewEnum.List" [useOpenWeather]=useOpenWeather [loading]=loading [forecastGroups]=forecastGroups [pageSize]=pageSize (expandedElementChanged)="onExpandedElementChanged($event)"></weather-table>

        <weather-map [hidden]="activeWindow !== OpenViewEnum.Map" [useOpenWeather]=useOpenWeather [loading]=loading [forecastGroups]=forecastGroups [expandedElement]=expandedElement></weather-map>

        <details-view [hidden]="activeWindow !== OpenViewEnum.Details"></details-view>
    </section>
</main>