import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NzMarks, NzSliderValue } from 'ng-zorro-antd/slider';
import { Store } from '@ngrx/store';
import { CommonFilters } from '../models/Args';
import { Observable } from 'rxjs';
import { updateState } from '../store/common-filters.actions';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';
import { FiltersState } from '../store/common-filters.reducer';
import { Place } from '../models/generated/Place.generated';
import { ExplorationService } from '../services/exploration.service';
import { CustomPlace } from '../models/Constants';

@Component({
  selector: 'weather-table',
  templateUrl: './weather-table.component.html',
  styleUrls: ['./weather-table.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('10ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class WeatherTableComponent {
  displayedColumns: string[] = ['place', 'avgTemperature', 'avgClouds'];
  expandedElement!: WeatherForecastHourGroup | null;
  isAllFiltersEnabled: boolean = false;
  distanceMin: number = 120;
  selectedDay: string = "today";
  selectedFrom: number = 1100;
  selectedTo: number = 2000;
  isQuickFilterExpanded: boolean = true;
  expandIcon: string = this.isQuickFilterExpanded ? "up-circle" : "down-circle";
  searchValue = '';
  originName: string = "origin";
  visible = false;

  @Input() loading: boolean = false;
  @Input() useOpenWeather: boolean = false;
  @Input() pageSize: number = 30;

  @Output() expandedElementChanged = new EventEmitter<WeatherForecastHourGroup | null>();

  public _forecastGroups: WeatherForecastHourGroup[] = [];
  public listOfDisplayData : WeatherForecastHourGroup[] = [];

  tempSortFn = (a: WeatherForecastHourGroup, b: WeatherForecastHourGroup) => a.avgTemperature - b.avgTemperature;
  cloudsSortFn = (a: WeatherForecastHourGroup, b: WeatherForecastHourGroup) => a.avgClouds! - b.avgClouds!;
  windSortFn = (a: WeatherForecastHourGroup, b: WeatherForecastHourGroup) => a.avgWindSpeed - b.avgWindSpeed;

  commonFilters$: Observable<FiltersState>;

  commonFiltersSaved: CommonFilters | null = null;

  get forecastGroups(): WeatherForecastHourGroup[] {
    return this._forecastGroups;
  }

  @Input()
  set forecastGroups(value: WeatherForecastHourGroup[]) {
    this._forecastGroups = value;
    this.listOfDisplayData = [...this._forecastGroups];
  }

  constructor(private explorationService: ExplorationService, private store: Store<{ commonFilters: FiltersState }>) {
    this.commonFilters$ = store.select('commonFilters');

    this.commonFilters$.subscribe((state: FiltersState) => {
      if (state.commonFilters === null) {
        console.log("weather table filter null");
        return;
      }
      else {
        if (state.commonFilters.origin?.name === CustomPlace) {
          this.originName = "your location";
        }
        else {
          this.originName = state.commonFilters.origin?.name ?? "origin";
        }

        this.commonFiltersSaved = JSON.parse(JSON.stringify(state.commonFilters));

        this.isAllFiltersEnabled = this.commonFiltersSaved!.isAllFiltersEnabled;
        this.distanceMin = this.commonFiltersSaved!.distanceMin;
        this.selectedDay = this.commonFiltersSaved!.timeRangeFilters.selectedDay;

        if (this.selectedDay === 'today') {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromToday;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToToday;
        }
        else if (this.selectedDay === 'tomorrow') {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow;
        } else {
          this.selectedFrom = +this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow;
          this.selectedTo = +this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow;
        }
      }

    });
  }
  
  ngOnInit(): void {
    this.listOfDisplayData = [...this._forecastGroups];
  }

  public expandedClicked() {
    this.isQuickFilterExpanded = !this.isQuickFilterExpanded;
    this.expandIcon = this.isQuickFilterExpanded ? "up-circle" : "down-circle";
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
  }

  selectedDayChanged(model: string) {
    this.selectedDay = model;

    if (!this.isQuickFilterExpanded) {
      this.isQuickFilterExpanded = true;
      this.expandIcon = "up-circle";
    }

    this.commonFiltersSaved!.timeRangeFilters.selectedDay = this.selectedDay;

    if (this.selectedDay === 'today') {
      this.selectedFrom =  this.commonFiltersSaved!.timeRangeFilters.selectedFromToday;
      this.selectedTo =  this.commonFiltersSaved!.timeRangeFilters.selectedToToday;
    } else if (this.selectedDay === 'tomorrow') {
      this.selectedFrom = this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow;
      this.selectedTo = this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow;
    } else {
      this.selectedFrom = this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow;
      this.selectedTo = this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow;
    }

    this.goEmit();
  }

  sliderTimeChanged(param: NzSliderValue) {
    const model: number[] = param as number[];
    this.selectedFrom = model[0];
    this.selectedTo = model[1];

    if (this.selectedDay === 'today') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromToday = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToToday = this.selectedTo;
    } else if (this.selectedDay === 'tomorrow') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow= this.selectedTo;
    } else {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow = this.selectedTo;
    }

    this.goEmit();
  }

  goEmit() {
    if (this.commonFiltersSaved === null) {
      return;
    }

    this.commonFiltersSaved!.isAllFiltersEnabled = this.isAllFiltersEnabled;
    this.commonFiltersSaved!.distanceMin = this.distanceMin;
    this.commonFiltersSaved!.timeRangeFilters.selectedDay = this.selectedDay;

    if (this.selectedDay === 'today') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromToday = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToToday = this.selectedTo;
    } else if (this.selectedDay === 'tomorrow') {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToTomorrow = this.selectedTo;
    } else {
      this.commonFiltersSaved!.timeRangeFilters.selectedFromAfterTomorrow = this.selectedFrom;
      this.commonFiltersSaved!.timeRangeFilters.selectedToAfterTomorrow = this.selectedTo;
    }

    this.store.dispatch(updateState({
      filtersState: { commonFilters: this.commonFiltersSaved }
    }));
  }

  toggleFiltersEnabled(newValue: boolean) {
    this.isAllFiltersEnabled = newValue;
    this.goEmit();
  }

  formatterTime(value: number): string {
    const val = value.toString();
    return val.includes("000") ? val.replace("000", "0:00") : val.replace("00", ":00");
  }

  formatterDistanceMinutes(value: number): string {
    return value.toString() + (value === 180 ? "+" : "") + " mins";
  }

  setExpandedElement(element: WeatherForecastHourGroup | null): void {
    this.expandedElement = element; // this.expandedElement === element ? null : 
    this.expandedElementChanged.emit(this.expandedElement);
  }

  getTemperatureStyling(temperature: number): string {
    if (temperature > 27) {
      return 'hot;'
    }
    else if (temperature > 18) {
      return 'comfortable';
    }
    else if (temperature > 14) {
      return 'almost-comfortable'
    }
    else if (temperature >= 10) {
      return 'little-cold';
    }
    else {
      return 'cold;'
    }
  }

  setCurrentLocationOrigin() {
    const that = this;
    this.explorationService.setCurrentLocationOrigin(() => {
      const newOrigin: Place | null = that.explorationService.getOrigin();

      if (newOrigin != null && newOrigin.name === CustomPlace) {
        that.originName = "your location"; 
      }

      this.commonFiltersSaved!.origin = this.explorationService.getOrigin();

      that.goEmit();
    });
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }

  search(onKeyUp: boolean = false, event: KeyboardEvent | null = null): void {
    if(!onKeyUp || event!.key == "Enter") {
      this.visible = false;
    }
    this.listOfDisplayData = this._forecastGroups.filter((item: WeatherForecastHourGroup) => item.place.indexOf(this.searchValue) !== -1);
  }

  marksDistanceMinutes: NzMarks = {
    10: {
      // style: {
      //   color: '#05f'
      // },
      label: '10 min'
    },
    30: `30`,
    60: `60 min`,
    90: `90`,
    120: `120 min`,
    180: {
      // style: {
      //   color: '#f50'
      // },
      label: '180+'
    },
  };

  marksTime: NzMarks = {
    900: {
      // style: {
      //   color: '#05f'
      // },
      label: '9:00'
    },
    1100: `11:00`,
    1400: `14:00`,
    1700: `17:00`,
    2000: `20:00`,
    2300: {
      // style: {
      //   color: '#f50'
      // },
      label: '23:00'
    },
  };
}
