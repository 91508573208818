<div *ngFor="let dayForecast of fullDetails.forecast8DaysMinimal; index as i" style="padding:5px;margin:5px;border-bottom: 1px solid rgb(85, 85, 85);"
            (click)="dayForecast.hourGroup.expand = !dayForecast.hourGroup.expand">
    <div style="float:left; max-width:calc(100% - 100px)">
        <span style="font-weight:500;">{{ i == 0 ? "Today" : dayForecast.dateDisplayText }}</span><br/>
        <span style="color:lightgrey;">{{ dayForecast.longDescription }}, {{ dayForecast.windSpeed | number: '1.0-0' }} km/h</span>
    </div>
    <div style="float:right;">
        <div style="float:right;padding:7px;background-color: rgba(51, 51, 93, 0.636);border-radius:12px;">
        <img [src]="dayForecast.iconFullUrl" [alt]="dayForecast.shortDescription" style="width:40px" />
        </div>
        <div style="float:right;margin-right: 10px;">
        <span style="font-weight:500;">{{ dayForecast.temperatureDay | number: '1.0-0' }}°</span><br/>
        <span style="color:lightgrey;">{{ dayForecast.temperatureMin | number: '1.0-0' }}°</span>
        </div>
    </div>
    <div style="clear:both;"></div>
    <div *ngIf="dayForecast.hourGroup.expand" style="font-size:10pt">
        <div style="float:left;color: lightgrey;width: 50%;">
        Wind<br/>
        Humidity<br/>
        <ng-container *ngIf="dayForecast.ultravioletIndex > 3">Ultraviolet<br/></ng-container>
        Sunrise/sunset
        </div>
        <div>
        {{ dayForecast.windSpeed | number: '1.0-0' }} km/h {{  dayForecast.windSpeedDirectionDisplayName }}
        <span style="font-size:9pt;font-style: italic;" *ngIf="dayForecast.windSpeedGust">({{ dayForecast.windSpeedGust | number: '1.0-0' }} km/h max)</span><br/>
        {{ dayForecast.humidity }}%<br/>
        <ng-container *ngIf="dayForecast.ultravioletIndex > 3">{{ dayForecast.ultravioletIndex | number: '1.0-0' }} / 11<br/></ng-container>
        {{ dayForecast.sunriseDisplayText }}, {{ dayForecast.sunsetDisplayText }}
        </div>
        <div style="clear:both;"></div>

        <ng-container *ngIf="dayForecast.hourGroup.hours.length > 0; else nonHourlySummary">
        <div style="margin-top:5px;">
            <weather-hours [hourGroup]="dayForecast.hourGroup" [horizontal]="true"></weather-hours>
        </div>
        </ng-container>
        <ng-template #nonHourlySummary>
        <div style="float:left;color: lightgrey;width: 50%;">
            Cloud cover %<br/>
            Precipitation chance %
        </div>
        <div>
            {{ dayForecast.clouds * 100 | number: '1.0-0' }}%<br/>
            {{ dayForecast.chanceOfPrecipitation * 100 | number: '1.0-0' }}% <span *ngIf="dayForecast.precipitationMm">({{ dayForecast.precipitationMm | number: "1.0-2" }} mm)</span>
        </div>
        <div style="clear:both;"></div>

        <div style="font-weight:500;">
            <div style="float:left;color: lightgrey;width: 50%;">
            Day: {{ dayForecast.temperatureDay | number: '1.0-0' }}° (feels {{ dayForecast.feelTemperatureDay | number: '1.0-0' }}°)<br/>
            Morning: {{ dayForecast.temperatureMorning | number: '1.0-0' }}° (feels {{ dayForecast.temperatureMorning | number: '1.0-0' }}°)<br/>
            Min: {{ dayForecast.temperatureMin | number: '1.0-0' }}°
            </div>
            <div>
            Night: {{ dayForecast.temperatureNight | number: '1.0-0' }}° (feels {{ dayForecast.feelTemperatureNight | number: '1.0-0' }}°)<br/>
            Evening: {{ dayForecast.temperatureEvening | number: '1.0-0' }}° (feels {{ dayForecast.temperatureEvening | number: '1.0-0' }}°)<br/>
            Max: {{ dayForecast.temperatureMax | number: '1.0-0' }}°
            </div>
            <div style="clear:both;"></div>
        </div>
        </ng-template>
    </div>
</div>
<div *ngIf="fullDetails && fullDetails.forecast8DaysMinimal && fullDetails.forecast8DaysMinimal.length < 9" style="margin:10px;display: flex; justify-content: center;">
    <span nz-icon nzType="info-circle" nzTheme="outline"
    nz-tooltip
    nzTooltipTitle="Starting from day 3 will load from alternative forecast provider"
    nzTooltipPlacement="topLeft"
    [nzTooltipTrigger]="'click'"
    style="margin-left:5px;padding: 10px;"></span>
    
    <button nz-button nzType="default" (click)="reloadMoreDay()">Load more</button>

    
</div>