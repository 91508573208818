import { Action, createReducer, on, select } from '@ngrx/store';
import { Place } from '../models/generated/Place.generated';
import { openDetails } from './details.actions';

export interface DetailsState {
    place: Place | null;
    switchView: boolean;
}

export const details : DetailsState = {
    place: null,
    switchView: false
}

const _detailsReducer = createReducer(
    details,
    on(openDetails, (state, { place, switchView }) => ({ place: place, switchView: switchView  }))
);

export function detailsReducer(state: DetailsState | undefined, action: Action) {
    return _detailsReducer(state, action);
}