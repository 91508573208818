import { Action, createReducer, on } from '@ngrx/store';
import { updateState } from './common-filters.actions';
import { CommonFilters, GoArgs } from '../models/Args';
import * as moment from 'moment';
import { SuggestedFilters } from '../models/generated/SuggestedFilters.generated';
import { Observable } from 'rxjs';
import { Region } from '../models/generated/Enum/Region.generated';
import { HttpClient, HttpParams } from '@angular/common/http';

export interface FiltersState {
  commonFilters: CommonFilters | null;
}

export const filtersState : FiltersState = {
  commonFilters: null,
}

// function getCommonFiltersInitialModel(): CommonFilters {

//     const savedFilterModel: string | null = localStorage.getItem("filterModel");

//     if (savedFilterModel) {
//       const data: GoArgs = JSON.parse(savedFilterModel);

//       return {
//         distanceMin: data.distanceMin,
//         isAllFiltersEnabled: data.isAllFiltersEnabled,
//         selectedDay: data.selectedDay,
//         selectedFrom: +data.selectedFrom.replace(':', ''),
//         selectedTo: +data.selectedTo.replace(':', '')
//       };
//     }
//     else {
//         const format: string = 'HH:mm:ss';
//         let beforeTime = moment('00:00:00', format), afterTime = moment('20:00:00', format);
//         let selectedDay = moment().isBetween(beforeTime, afterTime) ? "today" : "tomorrow";
//         let selectedFrom, selectedTo;

//         // Then let's set time for tomorrow or for today
//         if (selectedDay == "tomorrow") {
//             selectedFrom = 1100;
//             selectedTo = 2000;
//           }
//           else {
//             selectedTo = 2000;
//             selectedFrom = +moment().format('HH00');
      
//             // Check if it's before 11:00, then show from 11:00
//             if (moment().isBefore(moment("1100", 'HHmm'))) {
//               selectedFrom = 1100;
//             }
//           }

//         return {
//             distanceMin: 120,
//             isAllFiltersEnabled: true,
//             selectedDay: selectedDay,
//             selectedFrom: selectedFrom,
//             selectedTo: selectedTo,
//         };
//     }
// }

const _commonFiltersReducer = createReducer(
  filtersState,
    on(updateState, (existingState: FiltersState, { filtersState }): FiltersState => filtersState),
);

export function commonFiltersReducer(state: FiltersState | undefined, action: Action) {
    return _commonFiltersReducer(state, action);
}