import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WeatherForecastPlaceFullDetail } from '../models/generated/WeatherForecastPlaceFullDetail.generated';

@Component({
  selector: 'details-view-day-list-tab',
  templateUrl: './details-view-day-list-tab.component.html',
  styleUrls: ['./details-view-day-list-tab.component.css']
})
export class DetailsViewDayListTabComponent implements OnInit {

  @Input() fullDetails!: WeatherForecastPlaceFullDetail;
  
  @Output() load15day = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

    // Example function call
  reloadMoreDay(): void {
    // Emit the load15day event
    this.load15day.emit();
  }

}
