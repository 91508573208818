<div class="day-details-top" style="margin-right: 1rem; margin-left: 1rem; margin-bottom: 20px;">
  <p style="color:rgb(159, 159, 159);">updated: {{ fullDetails.lastUpdateDisplayText }} {{ fullDetails.placeName.startsWith('📌') ? fullDetails.placeName : ''}}</p>
  <p>{{ this.day == TodayTomorrow.Today ? "Today" : "Tomorrow" }} max {{ hourGroup.maxTemperature | number: '1.0-0' }}° · min  {{ hourGroup.minTemperature | number: '1.0-0' }}°</p>

  <div>
    <div style="float:left;">
    <span style="font-size: 40pt;">{{ mainTemperature | number: '1.0-0' }}°<span style="font-size: 30pt;">C</span></span>
    </div>
    <div style="float:right;padding:7px;background-color: rgba(51, 51, 93, 0.636);border-radius:12px;">
      <img [src]="mainIconSrc" style="height:80px; margin-right: 5px;float:right;" />
    </div>
    <div style="clear:both;"></div>
  </div>

  <div style="margin-top:10px;">
    <div style="float:left;">
      Feels like {{ mainFeelTemperature | number: '1.0-0' }}°</div>
    <div style="float:right;">
      {{ mainLongDescription }}
    </div>
    <div style="clear:both;"></div>
  </div>
</div>

<ng-container *ngIf="hourGroup.hours.length > 8">
  <weather-hours [showWindDirection]="true" [hourGroup]="hourGroup"></weather-hours>
</ng-container>
<!-- <ng-container>
  <weather-hours [showWindDirection]="true" [hourGroup]="hourGroup"
  [startIndex]="6" [endIndex]="hourGroup.hours.length"></weather-hours>
</ng-container> -->

<div class="day-details-info-rows" style="margin-right: 1rem; margin-left: 1rem; margin-top: 15px;">
  <div style="float:left;width: 49%;">
    Sunrise: {{ sunrise }}<br/>
    Humidity: {{ humidty }} %
  </div>
  <div style="float:right;width: 49%;"></div>
    Sunset: {{ sunset }}<br/>
    Ultraviolet: {{ ultravioletIndex  | number: '1.0-0' }} / 11
  <div style="clear:both;"></div>
</div>

<div style="margin-top:15px;margin-left:1rem;padding-bottom: 5px;">
  <a target="_blank" href="https://www.google.com/maps/search/?api=1&query={{fullDetails.coords!.lat}}+{{fullDetails.coords!.long}}">
    Google maps
  </a>
  |
  <a target="_blank" href="https://www.google.com/maps/search/?api=1&query={{fullDetails.placeName.replace(',', ' ')}}+attractions">
    attractions
  </a>
  |
  <a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination={{fullDetails.coords!.lat}}+{{fullDetails.coords!.long}}">
      directions
  </a>
   <!-- (driving distance {{ fullDetails.drivingDurationDisplayText }}) -->
</div>

<!-- <div *ngIf="element.webcamHtml" [innerHTML]="element.webcamHtml | safe: 'html'"></div> -->
