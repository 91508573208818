import { Component, OnInit } from '@angular/core';
import { ExplorationService } from '../services/exploration.service';
import { CookieService } from 'ngx-cookie-service';
import { GoArgs } from '../models/Args';
import * as moment from 'moment';
import { FavService } from '../services/fav.service';
import { DetailsState } from '../store/details.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WeatherForecastHourGroup } from '../models/generated/WeatherForecastHourGroup.generated';
import { openDetails } from '../store/details.actions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';

// Used for  window.goExploreNowRegion = 'Madeira'; in region specific index.html
declare var window: any;

export enum OpenView {
  Filter = "filter",
  List = "list",
  Map = "map",
  Details = "details",
}

@Component({
  selector: 'explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css'],
})
export class ExploreComponent implements OnInit {
  loading: boolean = true;
  loadingFailed: boolean = false;
  pageSize: number = 30; // passed to weather-table
  useOpenWeather: boolean = false; // set by call from lower component, and passed down into subcomponents

  private subscription: Subscription = new Subscription();

  expandedElement!: WeatherForecastHourGroup | null; // set by weather-table
  forecastGroups: WeatherForecastHourGroup[] = []; // retrieved from api and passed to table and map

  public OpenViewEnum = OpenView;
  activeWindow: OpenView = OpenView.List;

  detailsState$: Observable<DetailsState>;

  constructor(private favService: FavService,
    private explorationService: ExplorationService,
    private store: Store<{ details: DetailsState }>,
    private notification: NzNotificationService) {

      this.detailsState$ = store.select('details');

      this.detailsState$.subscribe((details: DetailsState) => {
        if (details.switchView) {
          this.navDetails();
        }
      });
  }

  async ngOnInit(): Promise<void> {
    const isiPhone = /iPhone/i.test(navigator.userAgent);
    const isMac = /Mac/i.test(navigator.userAgent);
    const isiPad = /iPad/i.test(navigator.userAgent);

    let appName: string;
    if (window.goExploreNowRegion && window.goExploreNowRegion === 'Latvia')
    {
      appName = 'lv.edza.kursaule.twa';
    }
    else {
      appName = 'lv.edza.madeira.twa';
    }

    if (
      !isiPhone && !isMac && !isiPad &&
      //this.explorationService.isMobileScreenWidth() &&
      !this.explorationService.isStandalone() &&
      !(await this.explorationService.isRelatedAppInstalled(appName))
    ) {
      setTimeout(
        () => {
          const closeCount = Number(localStorage.getItem('notificationCloseCount')) || 0;
          if (closeCount >= 3) {
            console.log('Notification has been closed 3 times, will not display again');
            return;
          }

          let instance;
          if (window.goExploreNowRegion === 'Latvia') {
            instance = this.notification.info(
              'Free app (no ads)',
              'Kur saule! in <br/><img src="https://www.gstatic.com/android/market_images/web/favicon_v2.ico" /> <a href="https://play.google.com/store/apps/details?id=lv.edza.kursaule.twa" target="_blank">Google Play Store</a>',
              { nzDuration: 0, nzPauseOnHover: true }
            );
          }
          else if (window.goExploreNowRegion === 'Lapalma') {
            // do nothin, no banner
            return;
          }
          else { // Default
            instance = this.notification.info(
              'Free app (no ads)',
              'Madeira Weather Plus in <br/><img src="https://www.gstatic.com/android/market_images/web/favicon_v2.ico" /> <a href="https://play.google.com/store/apps/details?id=lv.edza.madeira.twa" target="_blank">Google Play Store</a>',
              { nzDuration: 0, nzPauseOnHover: true }
            );
          }

          this.subscription.add(
            instance.onClose.subscribe(() => {
              console.log('The notification box has been closed');

              const newCloseCount = (Number(localStorage.getItem('notificationCloseCount')) || 0) + 1;
              localStorage.setItem('notificationCloseCount', newCloseCount.toString());
            })
          );
        }, 3500);
    }
  }

  onExpandedElementChanged(expandedElement: WeatherForecastHourGroup | null): void {
    this.expandedElement = expandedElement;
  }

  go(args: GoArgs): void {
    this.loadingFailed = false;
    this.loading = true;
    this.expandedElement = null;

    let { dateFrom, dateTo }: { dateFrom: moment.Moment; dateTo: moment.Moment; } = this.calculateSelectedDates(args);

    this.pageSize = 30;

    this.explorationService.getForecastGroups({
      origin: args.origin,
      distance: args.distanceMin,
      dateFrom: dateFrom.format("YYYY-MM-DD HH:mm:ss"),
      dateTo: dateTo.format("YYYY-MM-DD HH:mm:ss"),
      minAvgTemperature: args.minTemperature,
      maxAvgTemperature: args.maxTemperature,
      minAvgClouds: args.maxClouds / 100,
      maxWind: args.maxWind,
      favs: args.favs,
      maxRain: args.maxRain / 100,
      isAllFiltersEnabled: args.isAllFiltersEnabled,
      isTemperatureFilterEnabled: args.isTemperatureFilterEnabled,
      isCloudsFilterEnabled: args.isCloudsFilterEnabled,
      isWindFilterEnabled: args.isWindFilterEnabled,
      isRainFilterEnabled: args.isRainFilterEnabled,
    }).subscribe(regionForecast => {
      const groups = regionForecast.providerWeatherForecast![0].places;
      this.processForecastGroupsSuccess(groups);
    }, error => {
      this.processForecastGroupError(error);
    })
  }

  private processForecastGroupsSuccess(groups: WeatherForecastHourGroup[]): void {
    this.forecastGroups = groups;
    this.loading = false;
    console.log(groups);
  }

  private processForecastGroupError(error: any): void {
    this.loadingFailed = true;
    console.log(error);
  }

  private calculateSelectedDates(args: GoArgs) {
    let dateFrom: moment.Moment, dateTo: moment.Moment;

    if (args.selectedDay == "today") {
      dateFrom = moment();
      dateTo = moment();
    }
    else if (args.selectedDay == "tomorrow") {
      dateFrom = moment().add(1, 'days');
      dateTo = moment().add(1, 'days');
    }
    else if (args.selectedDay == "after-tomorrow") {
      dateFrom = moment().add(2, 'days');
      dateTo = moment().add(2, 'days');
    }

    // replace :00 with "", add those hours to from and to
    dateFrom = dateFrom!.clone().startOf('day').add(args.selectedFrom.replace(":00", ""), "hours");
    dateTo = dateTo!.clone().startOf('day').add(args.selectedTo.replace(":00", ""), "hours");
    return { dateFrom, dateTo };
  }

  navFilter() {
    this.activeWindow = OpenView.Filter;
  }

  navMap() {
    this.activeWindow = OpenView.Map;
  }

  navList() {
    this.activeWindow = OpenView.List;
  }

  navDetails() {
    this.activeWindow = OpenView.Details;
  }

  reload() {
    location.reload();
  }
}
